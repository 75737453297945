import { takeEvery, put } from 'redux-saga/effects';
import {
  MEMBER_POST_INSTORE_CARD_LINK_SUCCESS, MEMBER_POST_INSTORE_CARD_LINK_FAILURE,
  MEMBER_DELETE_INSTORE_OFFER_CARD_LINK_SUCCESS, MEMBER_DELETE_INSTORE_OFFER_CARD_LINK_FAILURE,
  MEMBER_DELETE_INSTORE_PAYMENT_CARD_FAILURE,
} from 'core/actions/member';
import {
  triggerCardLinkFailedGaEvent, triggerCardLinkSuccededGaEvent, triggerUnlinkSuccededGaEvent,
  triggerUnlinkFaieldGaEvent, triggerRemoveCardFaieldGaEvent,
} from 'core/modules/Instore2/actions';

function* handleAction(callback, event) {
  yield put(callback(event));
}

export default function* onGaSaga() {
  yield takeEvery(MEMBER_POST_INSTORE_CARD_LINK_SUCCESS, handleAction, triggerCardLinkSuccededGaEvent);
  yield takeEvery(MEMBER_POST_INSTORE_CARD_LINK_FAILURE, handleAction, triggerCardLinkFailedGaEvent);
  yield takeEvery(MEMBER_DELETE_INSTORE_OFFER_CARD_LINK_SUCCESS, handleAction, triggerUnlinkSuccededGaEvent);
  yield takeEvery(MEMBER_DELETE_INSTORE_OFFER_CARD_LINK_FAILURE, handleAction, triggerUnlinkFaieldGaEvent);
  yield takeEvery(MEMBER_DELETE_INSTORE_PAYMENT_CARD_FAILURE, handleAction, triggerRemoveCardFaieldGaEvent);
}
