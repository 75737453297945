import React from 'react';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import Modal from 'core/modules/Modal/Modal';
import WithoutAnyCardsView from 'core/modules/Instore2/components/InstoreOfferDetails/components/WithoutAnyCardsView';

function WithoutAnyCardsModal({ offer }) {
  return (
    <Modal className="mn_instoreModal mn_instoreOfferModal" options={{ shouldUpdateUrl: true }}>
      <WithoutAnyCardsView offer={offer} isModal />
    </Modal>
  );
}

export default (props) => <ComponentErrorBoundary><WithoutAnyCardsModal {...props} /></ComponentErrorBoundary>;
