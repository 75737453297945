/*
  This hook is used to Show in-store offer Modal with error message
  on card link failing when user clicks `Link offer` button on the offer tile
*/
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { clearInstoreLinkFailure } from 'core/modules/Instore2/actions';
import { useModal } from 'core/modules/Modal/ModalProvider';
import { useApiFeedbackManager } from 'core/hooks/useApiFeedbackManager';
import { selectInstoreIsEnabled, selectOrgId } from 'core/selectors/app';
import { selectDynamicCardLinksFeedback } from '../selectors';

export const useHandleCardLinkFailModal = () => {
  const isInstoreEnabled = useSelector(selectInstoreIsEnabled);
  const dynamicCardLinksFeedback = useSelector(selectDynamicCardLinksFeedback);
  const orgId = useSelector(selectOrgId);
  const { openModal } = useModal();

  // During United migration to Instore v2.0 `orgId === 76` check should be removed
  const isInstoreV2 = orgId !== 76;

  const { clearFeedback, feedbackData } = useApiFeedbackManager({
    data: dynamicCardLinksFeedback,
    callbackAction: clearInstoreLinkFailure,
  });

  const { hasLinkFailedFeedback } = feedbackData;

  useEffect(() => {
    if (!hasLinkFailedFeedback || !isInstoreEnabled || !isInstoreV2) {
      return () => {};
    }

    const { data = {}, options = {} } = feedbackData;
    const { offerId } = data;
    const { namespace, linkedFromTile } = options;

    if (offerId && namespace && linkedFromTile) {
      openModal({
        name: 'mt_instoreOffer2',
        params: {
          offerId,
          namespace,
          error: true,
        },
      });

      clearFeedback();
    }

    return () => {};
  }, [hasLinkFailedFeedback, feedbackData]);
};
