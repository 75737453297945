import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import { triggerUnlinkButtonIsClickedGaEvent } from 'core/modules/Instore2/actions';

export const useUnlinkOfferButton = (initiator, rclonMerchantName) => {
  const dispatch = useDispatch();
  const [shouldShowConfirm, setShouldShowConfirm] = useState(false);

  const showConfirmMessage = () => {
    dispatch(triggerUnlinkButtonIsClickedGaEvent(initiator, rclonMerchantName));
    setShouldShowConfirm(true);
  };
  const hideConfirmMessage = () => setShouldShowConfirm(false);

  return {
    UnlinkOfferButton,
    showConfirmMessage,
    hideConfirmMessage,
    shouldShowConfirm,
  };
};

function UnlinkOfferButton({ className = 'mn_basicButton', onClickCallback, children }) {
  return (
    <button className={className} onClick={onClickCallback}>{children || 'Unlink offer'}</button>
  );
}

export default (props) => <ComponentErrorBoundary><UnlinkOfferButton {...props} /></ComponentErrorBoundary>;
