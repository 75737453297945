import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteInstorePaymentCard } from 'core/actions/member';
import { selectDynamicallyDeletedCards, selectDynamicCardIsOngoingRemoving } from 'core/modules/Instore2/selectors';
import Loader from 'core/modules//Partials/Loader/Loader';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import { cardTypeNames, cardTypeImages } from 'core/modules/Instore2/utils/cardTypes';

function ManageCard({ card, shouldShowWarning, clearWarning }) {
  const { cardId, cardLastFour, cardType } = card;
  const [shouldShowConfirm, setShouldShowConfirm] = useState(false);

  const dispatch = useDispatch();
  const dynamicallyDeletedCards = useSelector(selectDynamicallyDeletedCards);
  const isOngoingCardRemove = useSelector(state => selectDynamicCardIsOngoingRemoving(state, cardId));

  const removeCard = () => dispatch(deleteInstorePaymentCard(cardId, {}, { cardLastFour }));
  const checkCardIsDeleted = () => dynamicallyDeletedCards.includes(cardId);
  const isCardDeleted = checkCardIsDeleted(cardId);

  const showConfirmMessage = () => {
    clearWarning?.();
    setShouldShowConfirm(true);
  };

  const hideConfirmMessage = () => setShouldShowConfirm(false);

  useEffect(() => {
    shouldShowWarning && setShouldShowConfirm(false);
  }, [shouldShowWarning]);

  if (isCardDeleted) {
    return (
      <div className="mn_manageCard mn_removedCardMessage">
        {cardTypeNames[cardType]} card ****{cardLastFour} was removed
      </div>
    );
  }

  return (
    <div className="mn_manageCard">
      <div className="mn_cardInfo">
        <div className="mn_cardImage">
          <img src={cardTypeImages[cardType]} alt={cardTypeNames[cardType]} />
        </div>
        <div className="mn_cardNumber">****{cardLastFour}</div>
        <button className="mn_basicButton" onClick={showConfirmMessage}>Remove card</button>
      </div>
      {shouldShowConfirm &&
        <div className="mn_confirmSection">
          <div className="mn_confirmMessage">
            Are you sure you want to remove this card? It will be unlinked from all offers.
          </div>
          <Loader isLoading={isOngoingCardRemove}>
            <div className="mn_confirmButtons">
              <button className="mn_basicButton" onClick={hideConfirmMessage}>No, keep card</button>
              <button className="mn_basicButton" onClick={removeCard}>Yes, remove card</button>
            </div>
          </Loader>
        </div>
      }
    </div>
  );
}

export default (props) => <ComponentErrorBoundary><ManageCard {...props} /></ComponentErrorBoundary>;
