import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import throttle from 'lodash/throttle';
import { scrollToElement } from 'core/utils/helpers/scrollToElement';

import { selectUserIsIdentified } from 'core/selectors/user';
import { selectLoginUrl } from 'core/selectors/app';
import { selectActiveMQ, selectIsMobileMQ } from 'core/modules/MediaQueries/selectors';
import { useModal } from 'core/modules/Modal/ModalProvider';

import RavenIndicator from 'core/modules/RavenIndicator/RavenIndicator';
import HeaderOnboardingCongratulations
  from 'core/modules/Header/HeaderOnboardingCongratulations/HeaderOnboardingCongratulations';
import { ShowOnMQ } from 'core/modules/MediaQueries/MediaQueries';
import SearchBar from 'core/modules/SearchBar/SearchBar';
import HeaderHolidayImage from 'core/modules/Header/HeaderHolidayImage/HeaderHolidayImage';
import SsoMigrationSiteNotice from 'org/modules/SsoMigrationSiteNotice/SsoMigrationSiteNotice';
import HeaderMainBar from './components/HeaderMainBar/HeaderMainBar';
import HeaderSecondaryBar from './components/HeaderSecondaryBar/HeaderSecondaryBar';

import config from './configs';
import './Header.scss';

// Run scroll handler no more than onse per this ammount of milliseconds.
// For desktop device this delay should be 0
const SCROLL_THROTTLE_DELAY = 50;

function Header() {
  const mainBarElement = useRef();
  const searchBarElement = useRef();
  const maxHeadersHeight = useRef(0);
  const brandLinksRef = useRef();
  const siteNotice = useRef();

  const userIsIdentified = useSelector(selectUserIsIdentified);
  const loginUrl = useSelector(selectLoginUrl);
  const isMobileMQ = useSelector(selectIsMobileMQ);
  const activeMQ = useSelector(selectActiveMQ);
  const { checkShouldUseStickyHeader, shouldUseInverseView } = config;
  const shouldUseStickyHeader = checkShouldUseStickyHeader(activeMQ, userIsIdentified);
  const { openModal } = useModal();

  const [isSticky, setIsSticky] = useState(false);
  const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [headerOffsetHeight, setHeaderOffsetHeight] = useState(0);

  const shouldHeaderBeSticky = () => {
    const isPageScrolled = window.pageYOffset > 0;

    return shouldUseStickyHeader && isPageScrolled && !isSidebarOpen && !isSearchBarOpen;
  };

  const scrollHandler = () => {
    // based on canjs 'top of fold' header: core/modules/header/v2/headerBase_control.js:hasToSkipStickyHeader
    if (isSearchBarOpen) {
      return false;
    }

    const isSticky = shouldHeaderBeSticky();
    const siteNoticeHeight = siteNotice.current?.scrollHeight;
    const headersHeight = siteNoticeHeight ? maxHeadersHeight.current - siteNoticeHeight : maxHeadersHeight.current;

    setIsSticky(isSticky);
    setIsSearchBarOpen(false);
    setHeaderOffsetHeight(isSticky ? headersHeight : 0);

    return true;
  };

  const hideSearchBarOnOutsideTouch = (event) => {
    if (
      (searchBarElement.current && searchBarElement.current.contains(event.target))
      || (mainBarElement.current && mainBarElement.current.contains(event.target))
    ) {
      return false;
    }

    if (isSearchBarOpen) {
      setIsSearchBarOpen(false);
    }

    return true;
  };

  const toggleOpenClassForHtml = () => document.documentElement.classList.toggle('mn_open', isSidebarOpen);

  const toggleOpenSearchForHtml = () =>
    document.documentElement.classList.toggle('mn_openSearch', isSearchBarOpen);

  const closeSideBar = (event) => {
    event.preventDefault();
    setIsSidebarOpen(false);
  };

  const toggleSidebar = (event) => {
    event.preventDefault();
    setIsSidebarOpen(!isSidebarOpen);
    setIsSearchBarOpen(false);
  };

  const toggleSearchBar = (event) => {
    event.preventDefault();
    if (isMobileMQ) {
      openModal({ name: 'mt_search' });
      return;
    }

    setIsSearchBarOpen(!isSearchBarOpen);
    setIsSidebarOpen(false);
  };

  const updateHeaderOnScroll = throttle(scrollHandler, SCROLL_THROTTLE_DELAY);

  useEffect(() => {
    toggleOpenClassForHtml();
    toggleOpenSearchForHtml();
    scrollHandler();
    // Calculate the header inner components heights after some delay to get the correct values.
    setTimeout(() => {
      const brandLinksHeight = get(brandLinksRef, 'current.clientHeight') || 0;
      const MAIN_HEADER_TOP_BORDER_WIDTH = 4;
      maxHeadersHeight.current = mainBarElement.current.clientHeight + brandLinksHeight + MAIN_HEADER_TOP_BORDER_WIDTH;
    }, 500);
  }, []);

  useEffect(() => {
    document.addEventListener('touchend', hideSearchBarOnOutsideTouch);

    return () => {
      document.removeEventListener('touchend', hideSearchBarOnOutsideTouch);
    };
  }, [activeMQ]);

  useEffect(() => {
    document.addEventListener('scroll', updateHeaderOnScroll);

    return () => {
      document.removeEventListener('scroll', updateHeaderOnScroll);
    };
  }, [activeMQ, isSidebarOpen, isSearchBarOpen]);

  useEffect(() => {
    toggleOpenClassForHtml();
    scrollToElement(document.body, 0);
  }, [isSidebarOpen]);

  const getClassNames = () => {
    const stickyClassName = isSticky ? 'mn_sticky' : '';
    const isAnonymousClassName = userIsIdentified ? '' : 'mn_isAnonymous';
    const inverseClassName = shouldUseInverseView ? 'mn_inverse' : '';

    return `mn_pageHeader ${stickyClassName} ${isAnonymousClassName} ${inverseClassName}`;
  };

  return (
    <>
      <SsoMigrationSiteNotice setRef={siteNotice} />
      <div className={getClassNames()} data-can-sticky={shouldUseStickyHeader}>
        <RavenIndicator />
        <div className="mn_headerInner">
          <HeaderHolidayImage />
          <HeaderMainBar
            setRef={mainBarElement}
            sidebarTriggerClickHandler={toggleSidebar}
            isSidebarOpen={isSidebarOpen}
            toggleSearchBar={toggleSearchBar}
            isSearchBarOpen={isSearchBarOpen}
            closeSideBar={closeSideBar}
          />
          <ShowOnMQ mobile>
            {!userIsIdentified &&
              <div className="mn_brandLinks" ref={brandLinksRef}>
                <a className="mn_button mn_loginLink" href={loginUrl}>Log in</a>
              </div>
            }
          </ShowOnMQ>
          <HeaderSecondaryBar sidebarTriggerClickHandler={toggleSidebar} />
        </div>
        <ShowOnMQ mobile>
          {isSearchBarOpen && <SearchBar
            hasFocusOnInit
            setRef={searchBarElement}
          />}
        </ShowOnMQ>
        <ShowOnMQ desktop><HeaderOnboardingCongratulations /></ShowOnMQ>
      </div>
      <div
        className="mn_pageHeaderOffset"
        style={{ flexBasis: headerOffsetHeight, height: headerOffsetHeight }}
      />
    </>
  );
}

export default Header;
