import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import Loader from 'core/modules/Partials/Loader/Loader';
import { selectDynamicCardIsOngoingLinking } from 'core/modules/Instore2/selectors';
import { postInstoreCardLink } from 'core/actions/member';

function LinkOfferButton({
  offer, cardId, onClickCallback, className = 'mn_button mn_linkOfferButton', initiator,
}) {
  const { offerId } = offer;
  const dispatch = useDispatch();
  const isOngoingLinking = useSelector(state => selectDynamicCardIsOngoingLinking(state, offerId, cardId));

  const linkOffer = () => {
    onClickCallback?.();
    dispatch(postInstoreCardLink(offerId, { cardId }, {}, { initiator, offer }));
  };

  return (
    <Loader isLoading={isOngoingLinking}>
      <button className={className} onClick={linkOffer}>Link offer</button>
    </Loader>
  );
}

export default (props) => <ComponentErrorBoundary><LinkOfferButton {...props} /></ComponentErrorBoundary>;
