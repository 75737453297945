import {
  MEMBER_POST_INSTORE_CARD_LINK, MEMBER_POST_INSTORE_CARD_LINK_SUCCESS, MEMBER_POST_INSTORE_CARD_LINK_FAILURE,
  MEMBER_DELETE_INSTORE_OFFER_CARD_LINK, MEMBER_DELETE_INSTORE_OFFER_CARD_LINK_SUCCESS,
  MEMBER_DELETE_INSTORE_OFFER_CARD_LINK_FAILURE,
  MEMBER_POST_INSTORE_MULTI_OFFER_CARD_LINK, MEMBER_POST_INSTORE_MULTI_OFFER_CARD_LINK_SUCCESS,
  MEMBER_POST_INSTORE_MULTI_OFFER_CARD_LINK_FAILURE,
} from 'core/actions/member';

import {
  INSTORE_FORCE_UPDATE, INSTORE_CARD_LINK_CLEAR_FAILS, INSTORE_CARD_LINK_CLEAR_EASYLINK_OFFER_ID,
} from 'core/modules/Instore2/actions/types';

const getEasyLinkedOfferId = (action) => (
  (action.context.options.easyLink && !action.payload.response[0]?.error) ? action.context.data.offerId : null
);

const filterOngoingLinks = (state, action) => {
  const { offerId, paymentCard = {} } = action.context.data;
  const { cardId } = paymentCard;

  return state.ongoingLinks.filter((ongoingLink) => {
    if (ongoingLink.offerId !== offerId) {
      return true;
    }

    return cardId && ongoingLink.paymentCard.cardId !== cardId;
  });
};

const handleFailResponse = (action) => {
  const { data = {}, options = {} } = action.context;

  // Suppress auto link API error messages since the user might not be aware something would happen to cause and error.
  // Only display warning if user explicitly clicks the "link offer" button and it triggers an API-related error.
  if (options.easyLink || options.autoLink) {
    return null;
  }

  return { hasLinkFailedFeedback: true, data, options };
};

const initialState = {
  cardLinks: [],
  ongoingLinks: [], // Offers that are linking in current moment
  easyLinkedOfferId: null,
  feedback: null,
  isLoading: false,
  isLoaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MEMBER_POST_INSTORE_CARD_LINK_SUCCESS: {
      const linkedOffers = action.payload.response.filter(({ error }) => !error);

      return {
        ...state,
        cardLinks: [...state.cardLinks, ...linkedOffers],
        feedback: null,
        ongoingLinks: filterOngoingLinks(state, action),
        easyLinkedOfferId: getEasyLinkedOfferId(action),
        isLoading: false,
        isLoaded: true,
      };
    }
    case MEMBER_POST_INSTORE_CARD_LINK_FAILURE: {
      return {
        ...state,
        feedback: handleFailResponse(action),
        ongoingLinks: filterOngoingLinks(state, action),
        isLoading: false,
        isLoaded: true,
      };
    }
    case MEMBER_POST_INSTORE_CARD_LINK: {
      return {
        ...state,
        ongoingLinks: [...state.ongoingLinks, action.data],
        isLoading: true,
        isLoaded: false,
      };
    }
    case MEMBER_POST_INSTORE_MULTI_OFFER_CARD_LINK_SUCCESS: {
      const linkedOffers = action.payload.response.filter(({ error }) => !error);
      return {
        ...state,
        cardLinks: [...state.cardLinks, ...linkedOffers],
        ongoingLinks: filterOngoingLinks(state, action),
        isLoading: false,
        isLoaded: true,
      };
    }
    case MEMBER_POST_INSTORE_MULTI_OFFER_CARD_LINK_FAILURE: {
      return {
        ...state,
        ongoingLinks: filterOngoingLinks(state, action),
        isLoading: false,
        isLoaded: true,
      };
    }
    case MEMBER_POST_INSTORE_MULTI_OFFER_CARD_LINK: {
      return {
        ...state,
        ongoingLinks: [...state.ongoingLinks, action.data],
        isLoading: true,
        isLoaded: false,
      };
    }
    case INSTORE_CARD_LINK_CLEAR_FAILS: {
      return {
        ...state,
        feedback: null,
      };
    }
    case INSTORE_CARD_LINK_CLEAR_EASYLINK_OFFER_ID: {
      return {
        ...state,
        easyLinkedOfferId: null,
      };
    }
    case INSTORE_FORCE_UPDATE: {
      return initialState;
    }
    case MEMBER_DELETE_INSTORE_OFFER_CARD_LINK_SUCCESS: {
      const unlinkedOffer = {
        offerId: action.context.data.offerId,
        isUnlinked: true,
      };

      return {
        ...state,
        cardLinks: [...state.cardLinks, unlinkedOffer],
        feedback: { hasUnlinkSuccessFeedback: true },
        ongoingLinks: filterOngoingLinks(state, action),
        isLoading: false,
        isLoaded: true,
      };
    }
    case MEMBER_DELETE_INSTORE_OFFER_CARD_LINK_FAILURE: {
      return {
        ...state,
        feedback: handleFailResponse(action),
        ongoingLinks: filterOngoingLinks(state, action),
        isLoading: false,
        isLoaded: true,
      };
    }
    case MEMBER_DELETE_INSTORE_OFFER_CARD_LINK: {
      return {
        ...state,
        ongoingLinks: [...state.ongoingLinks, action.data],
        isLoading: true,
        isLoaded: false,
      };
    }
    default: {
      return state;
    }
  }
};
