import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { offerTable as orgConfig } from 'org/configs/modules.config';
import { OFFER_TABLE_GET_SUCCESS, OFFER_TABLE_GET_FAILURE } from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      params: {
        content_type_id: 6,
        group_by_merchant: 0,
        sort_by: 'stop_date',
        sort_type: 'asc',
        limit: 12,
        with_offers: 1,
      },
      options: {
        success: OFFER_TABLE_GET_SUCCESS,
        failure: OFFER_TABLE_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
        sectionId: 10207,
      },
    },
    paginationOptions: {
      pageSizeList: [12, 24, 48],
      pageNumber: 1,
    },
    sortOptions: [
      {
        sortBy: 'stop_date',
        sortType: 'desc',
        viewName: 'Expiration Date',
      },
      {
        sortBy: 'name',
        sortType: 'asc',
        viewName: 'Store name',
      },
      {
        sortBy: 'earn_rate',
        sortType: 'desc',
        viewName: 'Earn rate',
      },
    ],
    sortOptionsDropdown: [
      {
        sortBy: 'stop_date',
        sortType: 'desc',
        viewName: 'Expiring soon',
      },
      {
        sortBy: 'earn_rate',
        sortType: 'desc',
        viewName: 'Highest earn rate',
      },
      {
        sortBy: 'create_date',
        sortType: 'desc',
        viewName: 'Newest',
      },
      {
        sortBy: 'name',
        sortType: 'asc',
        viewName: 'Store (A-Z)',
      },
    ],
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
