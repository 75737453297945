import React from 'react';
import isEmpty from 'lodash/isEmpty';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import Card from './components/Card';

import './Cards.scss';

function Cards({
  offer, shouldShowLinkedOnly, clearWarning, initiator,
}) {
  const { cards, linkedCards } = offer;
  const cardsToShow = shouldShowLinkedOnly ? linkedCards : cards;

  if (isEmpty(cardsToShow)) {
    return null;
  }

  return (
    <div className="mn_instoreCards">
      <ul className="mn_cardsList">
        {cardsToShow.map((card) => (
          <Card
            key={card.cardId}
            card={card}
            offer={offer}
            clearWarning={clearWarning}
            initiator={initiator}
          />
        ))}
      </ul>
    </div>
  );
}

export default (props) => <ComponentErrorBoundary><Cards {...props} /></ComponentErrorBoundary>;
