import React from 'react';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import { AddCardButton, Disclaimer, Logo } from 'core/modules/Instore2/partials';
import { InstoreOfferModalTitle, InstoreOnlyPill } from '../partials';

import '../InstoreOfferDetails.scss';

function WithoutAnyCardsView({ offer, isModal }) {
  const {
    displayRate, currency, rclonMerchantName, disclaimer, offerId,
  } = offer;

  return (
    <div className="mn_instoreOfferDetails mn_withoutAnyCardsView">
      {!isModal && <InstoreOnlyPill />}
      {!isModal && <Logo offer={offer} />}
      <InstoreOfferModalTitle offer={offer} />
      <div id="mn_dialogDescription" className="mn_offerInfo">
        Add a card to earn {displayRate} {currency} in-store at {rclonMerchantName}
      </div>
      <AddCardButton offerId={offerId} merchantName={rclonMerchantName} />
      <Disclaimer disclaimer={disclaimer} />
    </div>
  );
}

export default (props) => <ComponentErrorBoundary><WithoutAnyCardsView {...props} /></ComponentErrorBoundary>;
