import React from 'react';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import { AddCardButton } from 'core/modules/Instore2/partials';
import { InstoreOfferModalTitle } from '../partials';

import '../InstoreOfferDetails.scss';

function EasyLinkWithoutAnyCardView({ offer }) {
  const {
    displayRate, currency, rclonMerchantName, offerId,
  } = offer;

  return (
    <div className="mn_instoreOfferDetails mn_easyLinkWithoutAnyCardsView">
      <div className="mn_addCardIcon" />
      <InstoreOfferModalTitle offer={offer} shouldShowRate />
      <div className="mn_easyLinkFaq">
        <p>
          {rclonMerchantName} also offers you the ability to earn {displayRate} {currency} when you shop in-store.
          Add any Visa, Mastercard, or American Express card in your wallet to get started.
        </p>
        <p>If you made a purchase online, we'll continue to track your purchase as usual.</p>
      </div>
      <AddCardButton offerId={offerId} merchantName={rclonMerchantName} />
    </div>
  );
}

export default (props) => <ComponentErrorBoundary><EasyLinkWithoutAnyCardView {...props} /></ComponentErrorBoundary>;
