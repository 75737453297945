/*
  This hook is used to updated Instore Cookie value
*/

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { selectDynamicCardHolderId } from 'core/modules/Instore2/selectors';
import { selectCookieDomain, selectIsHttps } from 'core/selectors/env';

const COOKIE_NAME = 'mn_instore_info';

export const useHandleInstoreCookie = () => {
  const cardholderId = useSelector(selectDynamicCardHolderId);
  const cookieDomain = useSelector(selectCookieDomain);
  const isHttps = useSelector(selectIsHttps);

  useEffect(() => {
    try {
      const instoreCookie = JSON.parse(Cookies.get(COOKIE_NAME));

      if (!instoreCookie.cardholderId && cardholderId) {
        const updatedInstoreCookie = {
          ...instoreCookie,
          cardholderId,
        };

        Cookies.set(COOKIE_NAME, JSON.stringify(updatedInstoreCookie), {
          path: '/',
          domain: cookieDomain,
          secure: isHttps,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, [cardholderId]);
};
