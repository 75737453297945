import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { instoreAdConfig as orgConfig } from 'org/configs/modules.config';

import {
  INSTORE_AD_OFFERS_GET_SUCCESS, INSTORE_AD_OFFERS_GET_FAILURE,
} from '../actions/types';

const defaultConfig = {
  defaults: {
    api: {
      options: {
        success: INSTORE_AD_OFFERS_GET_SUCCESS,
        failure: INSTORE_AD_OFFERS_GET_FAILURE,
      },
      params: {
        type: 'instore',
        active: 1,
      },
    },
    carousel: {
      hasDots: false,
      hasArrows: true,
      items: 4,
      breakpoints: {
        m: { hasDots: true },
        l: { hasDots: false },
      },
    },
    shouldForceUseStoreLogo: true,
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
