/*
  The function is used to handle the state of the offer, based on:
  1. Offers data received from the API.
  2. Dynamic data like: deleting cards, linking/unlinking cards to the offers, etc.
     that could happen during the user's session.
*/

import dayjs from 'core/utils/dayjs';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';

const EXPIRY_DAY_CAP = 2;

export const handleOfferState = ({
  offer, deletedCards, cardLinks, currentDate,
}) => {
  const {
    eligibleCards, linkedCards, offerId, rclonMerchantName, rclonMerchantId, displayRate, currency,
    carteraMerchantId, carteraMerchantLogoUrls, imageUrl, description, disclaimer,
  } = offer;

  const cards = handleCards({
    eligibleCards, linkedCards, deletedCards, cardLinks, offerId,
  });

  const allLinkedCards = getAllLinkedCards(cards);
  const hasLinkedCards = !isEmpty(allLinkedCards);

  const allEligibleCards = getAllEligibleCards(cards);
  const hasEligibleCards = !isEmpty(allEligibleCards);

  const daysToExpire = calculateDaysToExpire({ allLinkedCards, currentDate });

  const expiringSoon = isNumber(daysToExpire) && daysToExpire <= EXPIRY_DAY_CAP;

  return {
    offerId,
    carteraMerchantLogoUrls,
    imageUrl,
    cards,
    linkedCards: allLinkedCards,
    eligibleCards: allEligibleCards,
    hasLinkedCards,
    hasEligibleCards,
    daysToExpire,
    expiringSoon,
    displayRate,
    currency,
    rclonMerchantName,
    rclonMerchantId,
    carteraMerchantId,
    disclaimer: disclaimer || description,
  };
};

function handleCards({
  eligibleCards, linkedCards, cardLinks, offerId, deletedCards,
}) {
  // Filter dynamically deleted cards
  const filterDeletedCards = (cards) => cards.filter(item => !deletedCards.includes(item.cardId));

  const filteredLinkedCards = filterDeletedCards(linkedCards, deletedCards);
  const filteredEligibleCards = filterDeletedCards(eligibleCards, deletedCards);

  const allCards = [...filteredLinkedCards, ...filteredEligibleCards];

  // Handle dynamically linked/ulinked cards
  // 1. Ignore changes not associated with the current offer
  // 2. If user dynamically unlinked offer, set `isLinked` to false for all available cards
  // 3. If user dynamically linked card(s) to the offer, set `isLinked` to true for this card(s)
  return cardLinks.reduce((result, item) => {
    // 1
    if (item.offerId !== offerId) {
      return result;
    }

    // 2
    if (item.isUnlinked) {
      return result.map(card => ({ ...card, isLinked: false }));
    }

    // 3
    return result.map(card => {
      if (card.cardId === item.paymentCard.cardId) {
        return {
          ...card,
          isLinked: true,
          linkExpirationDate: dayjs(item.expirationDate).utc().format(),
        };
      }
      return card;
    });
  }, allCards);
}

// Get all linked cards including cards that are dynamically linked during the user's session
function getAllLinkedCards(cards) {
  return cards.filter(({ isLinked }) => isLinked);
}

// Get all eligible cards including cards that are added during the user's session
function getAllEligibleCards(cards) {
  return cards.filter(({ isLinked }) => !isLinked);
}

function calculateDaysToExpire({ allLinkedCards, currentDate }) {
  const dates = allLinkedCards
    .map(({ linkExpirationDate }) => dayjs(linkExpirationDate));

  if (isEmpty(dates)) {
    return null;
  }

  const oldestDate = dayjs.min(dates);
  const expiryDays = oldestDate.diff(currentDate, 'days');

  return expiryDays;
}
