import { all } from 'redux-saga/effects';
import onAddCardSaga from './onAddCardSaga';
import onUnlinkSaga from './onUnlinkSaga';
import onGaSaga from './onGaSaga';

export default function* rootSaga() {
  yield all([
    onAddCardSaga(),
    onUnlinkSaga(),
    onGaSaga(),
  ]);
}
