import React, {
  createContext, useContext, useState, useMemo,
} from 'react';
import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';

const ModalContext = createContext({});

function ModalProvider({ children }) {
  const [modals, setModals] = useState([]);

  const openModal = (modal) => {
    if (modal.params) {
      modal.params = omitBy(modal.params, isUndefined);
    }

    setModals([modal]);
  };

  const closeModal = (callback) => {
    if (typeof callback === 'function') { callback(); }
    setModals((old) => old.slice(0, old.length - 1));
  };

  const modal = modals[modals.length - 1];

  const value = useMemo(() => ({
    modal, openModal, closeModal,
  }), [modal]);

  return (
    <ModalContext.Provider value={value}>
      {children}
    </ModalContext.Provider>
  );
}

export const useModal = () => useContext(ModalContext);

export default (props) => <ComponentErrorBoundary><ModalProvider {...props} /></ComponentErrorBoundary>;
