import React from 'react';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';

function DaysToExpire({ offer }) {
  const { daysToExpire, hasLinkedCards } = offer;

  if (!hasLinkedCards) {
    return null;
  }

  return (
    <div className="mn_daysLeft">
      {daysToExpire} day{daysToExpire === 1 ? '' : 's'} <span>left</span> to shop
    </div>
  );
}

export default (props) => <ComponentErrorBoundary><DaysToExpire {...props} /></ComponentErrorBoundary>;
