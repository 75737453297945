import amexImage from 'core/assets/images/icons/card_amex.svg';
import visaImage from 'core/assets/images/icons/card_visa.svg';
import mastercardImage from 'core/assets/images/icons/card_mastercard.svg';

export const cardTypeNames = {
  VISA: 'Visa',
  MASTERCARD: 'Mastercard',
  AMEX: 'Amex',
};

export const cardTypeImages = {
  VISA: visaImage,
  MASTERCARD: mastercardImage,
  AMEX: amexImage,
};
