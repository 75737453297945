import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { creditCardMarketingBanner as orgConfig } from 'org/configs/modules.config';
import { CREDIT_CARD_MARKETING_BANNER_GET_SUCCESS, CREDIT_CARD_MARKETING_BANNER_GET_FAILURE } from '../actions/types';
import { useGetCreditCardMarketingBanner } from '../hooks/useGetCreditCardMarketingBanner';

const defaultConfig = {
  defaults: {
    hooks: {
      useGetCreditCardMarketingBanner,
    },
    api: {
      params: {
        content_group_id: 11430,
        fields: [
          'assets',
          'clickUrl',
          'contentItemTypeId',
        ],
      },
      options: {
        success: CREDIT_CARD_MARKETING_BANNER_GET_SUCCESS,
        failure: CREDIT_CARD_MARKETING_BANNER_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
        sectionId: 10457,
      },
    },
    shouldShowButtonView: true,
    shouldShowCardTerms: false,
    getShowCardTip: () => false,
    shouldShowCardHeading: false,
    shouldShowCreditCardImage: true,
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
