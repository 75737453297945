/*
  This hook is designed to respond to various API responses and show user feedback such as successful
  messages and warnings. It monitors changes in the global Redux state provided as `data` in this hook
  and transforms this into a state managed at the hook level. The `callbackAction` is used to clear
  the Redux state (`data`), as these interactions are already observed and handled within the hook.
*/

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export const useApiFeedbackManager = ({ data, callbackAction }) => {
  const dispatch = useDispatch();

  const [shouldShowFeedback, setShouldShowFeedback] = useState(false);
  const [feedbackData, setFeedbackData] = useState({});

  const clearFeedback = () => {
    setShouldShowFeedback(false);
    setFeedbackData({});
  };

  useEffect(() => {
    if (data) {
      setShouldShowFeedback(true);
      setFeedbackData(data);
      callbackAction && dispatch(callbackAction());
    }
  }, [data]);

  return {
    shouldShowFeedback,
    clearFeedback,
    feedbackData,
  };
};
