import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { selectCurrentDate } from 'core/selectors/env';
import { handleOfferState } from 'core/modules/Instore2/utils/handleOfferState';
import { selectInstoreInfo } from 'core/selectors/user';

const selectInstore = state => state.instore2;

/* Config */
export const selectInstoreConfig = createSelector(selectInstore, ({ config }) => config);

/* Payment cards */
export const selectPaymentCardsReducer = createSelector(
  selectInstore,
  ({ paymentCardsReducer }) => paymentCardsReducer,
);

export const selectPaymentCards = createSelector(selectPaymentCardsReducer, ({ cards }) => cards);
export const selectPaymentCardsIsLoaded = createSelector(selectPaymentCardsReducer, ({ isLoaded }) => isLoaded);
export const selectPaymentCardsIsLoading = createSelector(selectPaymentCardsReducer, ({ isLoading }) => isLoading);

/* Token */
export const selectTokenReducer = createSelector(selectInstore, ({ tokenReducer }) => tokenReducer);
export const selectToken = createSelector(selectTokenReducer, ({ token }) => token || '');
export const selectTokenCardHolderId = createSelector(selectTokenReducer, ({ cardHolderId }) => cardHolderId || '');
export const selectTokenIsLoaded = createSelector(selectTokenReducer, ({ isLoaded }) => isLoaded);
export const selectTokenIsLoading = createSelector(selectTokenReducer, ({ isLoading }) => isLoading);

/* Dynamic Card holder Id */
export const selectDynamicCardHolderId = createSelector(
  [selectInstoreInfo, selectTokenCardHolderId],
  (instoreInfo, tokenCardHolderId) => instoreInfo.cardholderId || tokenCardHolderId,
);

/* Instore locations */
export const selectInstoreLocationsReducer = createSelector(
  selectInstore,
  ({ instoreLocations }) => instoreLocations,
);

export const selectInstoreLocations =
  createSelector(selectInstoreLocationsReducer, ({ locations }) => locations);
export const selectInstoreLocationsIsLoaded =
  createSelector(selectInstoreLocationsReducer, ({ isLoaded }) => isLoaded);
export const selectInstoreLocationsIsLoading =
  createSelector(selectInstoreLocationsReducer, ({ isLoading }) => isLoading);

/* Instore offers title */
export const selectInstoreOffersTitleReducer =
  createSelector(selectInstore, ({ instoreOffersTitleReducer }) => instoreOffersTitleReducer);

export const selectInstoreOffersTitleByNamespace = createSelector(
  [
    selectInstoreOffersTitleReducer,
    (state, namespace = 'default') => namespace,
  ],
  (instoreOffersTitleReducer, namespace) => {
    const { defaults = {} } = instoreOffersTitleReducer;
    return instoreOffersTitleReducer[`namespace-${namespace}`] || defaults;
  },
);

export const makeSelectInstoreTitleOffers = () => (
  createSelector(selectInstoreOffersTitleByNamespace, ({ title }) => title)
);
export const makeSelectInstoreOffersTitleIsLoaded = () => (
  createSelector(selectInstoreOffersTitleByNamespace, ({ isLoaded }) => isLoaded)
);
export const makeSelectInstoreOffersTitleIsLoading = () => (
  createSelector(selectInstoreOffersTitleByNamespace, ({ isLoading }) => isLoading)
);

/* Instore offers */
export const selectInstoreOffersReducer =
  createSelector(selectInstore, ({ instoreOffersReducer }) => instoreOffersReducer);

export const selectInstoreOffersByNamespace = createSelector(
  [
    selectInstoreOffersReducer,
    (state, namespace = 'default') => namespace,
  ],
  (instoreOffersReducer, namespace) => {
    const { defaults = {} } = instoreOffersReducer;
    return instoreOffersReducer[`namespace-${namespace}`] || defaults;
  },
);

export const makeSelectInstoreOffers = () => (
  createSelector(selectInstoreOffersByNamespace, ({ offers }) => offers)
);
export const makeSelectInstoreOffersIsLoaded = () => (
  createSelector(selectInstoreOffersByNamespace, ({ isLoaded }) => isLoaded)
);
export const makeSelectInstoreOffersIsLoading = () => (
  createSelector(selectInstoreOffersByNamespace, ({ isLoading }) => isLoading)
);

/* Dynamic card links */
export const selectDynamicCardLinksReducer =
  createSelector(selectInstore, ({ dynamicCardLinksReducer }) => dynamicCardLinksReducer);

export const selectDynamicCardLinks =
  createSelector(selectDynamicCardLinksReducer, ({ cardLinks }) => cardLinks);

export const selectDynamicCardOngoingLinks =
  createSelector(selectDynamicCardLinksReducer, ({ ongoingLinks }) => ongoingLinks);

export const selectDynamicCardLinksIsLoading =
  createSelector(selectDynamicCardLinksReducer, ({ isLoading }) => isLoading);

export const selectDynamicCardLinksIsLoaded =
  createSelector(selectDynamicCardLinksReducer, ({ isLoaded }) => isLoaded);

export const selectDynamicCardLinksFeedback =
  createSelector(selectDynamicCardLinksReducer, ({ feedback }) => feedback);

export const selectDynamicCardEasyLinkedOfferId =
  createSelector(selectDynamicCardLinksReducer, ({ easyLinkedOfferId }) => easyLinkedOfferId);

export const selectDynamicCardIsOngoingLinking = createSelector(
  [
    selectDynamicCardOngoingLinks,
    (state, offerId) => offerId,
    (state, offerId, cardId) => cardId,
  ],
  (ongoingLinks, offerId, cardId) => {
    // Check for offers linked from `Linked New Card to Linked Offers` Modal
    if (!offerId) {
      return ongoingLinks.some(item => item.paymentCard?.cardId === cardId);
    }

    // Check for offer linked to card in `Instore Offer` Modal
    if (cardId) {
      return ongoingLinks.some((item) => {
        if (item.offerId !== offerId) return false;

        // Check if offer is linking to all unlinked cards OR to some unique card
        return isEmpty(item.paymentCard) || item.paymentCard?.cardId === cardId;
      });
    }

    // Check for offer linked to all available cards from the `instore Offer` Modal or Instore Offer Tile
    return ongoingLinks.some(item => item.offerId === offerId);
  },
);

/* Dynamic card manages */
export const selectDynamicCardManagesReducer =
  createSelector(selectInstore, ({ dynamicCardManagesReducer }) => dynamicCardManagesReducer);

export const selectDynamicallyDeletedCards =
  createSelector(selectDynamicCardManagesReducer, ({ deletedCards }) => deletedCards);

export const selectDynamicCardOngoingRemoves =
  createSelector(selectDynamicCardManagesReducer, ({ ongoingDeletes }) => ongoingDeletes);

export const selectDynamicCardRemoveFeedback =
  createSelector(selectDynamicCardManagesReducer, ({ feedback }) => feedback);

export const selectDynamicCardIsOngoingRemoving = createSelector(
  [
    selectDynamicCardOngoingRemoves,
    (state, cardId) => cardId,
  ],
  (ongoingRemoves, cardId) => ongoingRemoves.some(card => card.cardId === cardId),
);

/* Instore dynamic offers */
/* This selector takes:
    1. offers data from the API
    2. dynamic data like: deleting cards, linking/unlinking cards to the offers, etc.
       that could happen during the user's session.
    Then this data is used to dynamically handle the state of each offer
    with the help of the `handleOfferState` function.
*/
export const makeSelectDynamicInstoreOffers = () => (
  createSelector([
    selectInstoreOffersByNamespace,
    selectDynamicallyDeletedCards,
    selectDynamicCardLinks,
    selectCurrentDate,
  ], (instoreOffersNamedReducer, deletedCards, cardLinks, currentDate) => {
    const { offers } = instoreOffersNamedReducer;
    const dynamicOffers = offers.map((offer) => handleOfferState({
      offer, deletedCards, cardLinks, currentDate,
    }));
    return dynamicOffers;
  })
);
