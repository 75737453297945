/*
  Mykhailo:
    After United migration to the instore v.2, this parser should be revisit and refactored.
    Unused flags and functionality should be removed.
*/
import { selectCurrentDate } from 'core/selectors/env';
import dayjs from 'core/utils/dayjs';

export const EXPIRY_DAY_CAP = 2;

export default (response, state) =>
  response.map((offer) => {
    const item = { ...offer };
    const { eligibleCards = [], linkedCards = [] } = offer;
    let daysToExpireHolder = null;

    if ((eligibleCards.length) || (linkedCards.length)) {
      item.hasEligibleOrLinkedCards = true;
    }

    eligibleCards.forEach(card => { card.isLinked = false; });
    linkedCards.forEach(card => { card.isLinked = true; });

    item.linked = !!linkedCards.length;
    item.expiringSoon = false;
    const currentDate = selectCurrentDate(state);

    if (item.linked) {
      item.linkedCards.map((linkedCard) => {
        const linkCardExpiryDate = dayjs(linkedCard.linkExpirationDate);
        const daysToExpire = linkCardExpiryDate.diff(currentDate, 'days');
        const isExpiring = daysToExpire <= EXPIRY_DAY_CAP;

        if (daysToExpireHolder === null || daysToExpireHolder > daysToExpire) {
          daysToExpireHolder = daysToExpire;
        }

        if (isExpiring) {
          item.expiringSoon = true;
          linkedCard.expiringSoon = true; // eslint-disable-line no-param-reassign
        } else {
          linkedCard.expiringSoon = false; // eslint-disable-line no-param-reassign
        }

        return linkedCard;
      });

      item.daysToExpire = daysToExpireHolder;
    }

    return item;
  });
