import React from 'react';
import { useSelector } from 'react-redux';
import { selectUserIsAuthenticated } from 'core/selectors/user';
import { selectDynamicCardRemoveFeedback, selectDynamicCardLinksIsLoading } from 'core/modules/Instore2/selectors';
import { clearCardDeleteFailure } from 'core/modules/Instore2/actions';
import { useGetPaymentCards } from 'core/modules/Instore2/hooks/useGetPaymentCards';
import { useApiFeedbackManager } from 'core/hooks/useApiFeedbackManager';
import Modal from 'core/modules/Modal/Modal';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import SectionTitle from 'core/modules/SectionTitle/SectionTitle';
import Loader from 'core/modules/Partials/Loader/Loader';
import SignInModal from 'core/modules/Instore2/components/Modals/SignInModal/SignInModal';
import { AddCardButton, RemoveCardWarning } from 'core/modules/Instore2/partials';
import ManageCard from './components/ManageCard/ManageCard';

import './ManageCardsModal.scss';

function ManageCardsModal() {
  const { cards, isLoading } = useGetPaymentCards();
  const isUserAuthenticated = useSelector(selectUserIsAuthenticated);
  const dynamicCardRemoveFeedback = useSelector(selectDynamicCardRemoveFeedback);

  // The loader should be also shown from the moment when user added a new card and until this card is
  // autlinked (if it is allowed for current user) to all already linked offers and updated offers are re-fetched
  const dynamicCardLinksIsLoading = useSelector(selectDynamicCardLinksIsLoading);

  const { shouldShowFeedback, clearFeedback, feedbackData } = useApiFeedbackManager({
    data: dynamicCardRemoveFeedback,
    callbackAction: clearCardDeleteFailure,
  });

  if (!isUserAuthenticated) {
    return <SignInModal />;
  }

  return (
    <Modal className="mn_instoreModal mn_manageCardsModal" options={{ shouldUpdateUrl: true }}>
      <SectionTitle id="mn_dialogTitle" title="Your available cards." />
      <div id="mn_dialogDescription" className="mn_info">
        You can use any eligible Visa, Mastercard or American Express card in your wallet.*
      </div>
      <RemoveCardWarning hasError={shouldShowFeedback} feedbackData={feedbackData} />
      <div className="mn_cards">
        <Loader isLoading={isLoading || dynamicCardLinksIsLoading}>
          {cards.map((card) => (
            <ManageCard
              key={card.cardId}
              card={card}
              shouldShowWarning={shouldShowFeedback}
              clearWarning={clearFeedback}
            />
          ))}
        </Loader>
      </div>
      <AddCardButton>Add a new card</AddCardButton>
    </Modal>
  );
}

export default (props) => <ComponentErrorBoundary><ManageCardsModal {...props} /></ComponentErrorBoundary>;
