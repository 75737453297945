// When user unlink any offer update the `easy_link_suppression` property in the `mn_properties` cookie to "1"
import Cookies from 'js-cookie';
import { select, takeEvery, put } from 'redux-saga/effects';
import { selectCookieDomain, selectIsHttps } from 'core/selectors/env';
import { updateEasyLinkSuppresionProperty } from 'core/modules/App/actions';

import { MEMBER_DELETE_INSTORE_OFFER_CARD_LINK_SUCCESS } from 'core/actions/member';

function* handlePostOfferUnlinkActions() {
  const cookieDomain = yield select(selectCookieDomain);
  const isHttps = yield select(selectIsHttps);

  const updateCookie = () => {
    const COOKIE_NAME = 'mn_properties';
    try {
      const propertiesCookie = JSON.parse(Cookies.get(COOKIE_NAME));

      if (propertiesCookie && propertiesCookie.easy_link_suppression !== '1') {
        const updatedPropertiesCookie = {
          ...propertiesCookie,
          easy_link_suppression: '1',
        };

        Cookies.set(COOKIE_NAME, JSON.stringify(updatedPropertiesCookie), {
          path: '/',
          domain: cookieDomain,
          secure: isHttps,
          sameSite: 'Lax',
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  yield put(updateEasyLinkSuppresionProperty());
  updateCookie();
}

export default function* onUnlinkSaga() {
  yield takeEvery(MEMBER_DELETE_INSTORE_OFFER_CARD_LINK_SUCCESS, handlePostOfferUnlinkActions);
}
