import { BONUS_BANNER_ITEM_VIEW } from '../actions/types';
import {
  TOP_VHP_BANNER, BOTTOM_VHP_BANNER, TOP_MHP_BANNER, BOTTOM_MHP_BANNER,
} from 'core/modules/BonusHomeBanner/utils/instanceTypes';

export const bonusHomeBannerGaActionEvents = {
  [BONUS_BANNER_ITEM_VIEW]: ({ bonusItem, instanceType }) => {
    const customCategory = {
      [TOP_VHP_BANNER]: 'Automatic Public Bonus: VHP Hero Banner',
      [BOTTOM_VHP_BANNER]: 'Automatic Public Bonus: VHP Bottom Banner',
      [TOP_MHP_BANNER]: 'Automatic Public Bonus: MHP Hero Banner',
      [BOTTOM_MHP_BANNER]: 'Automatic Public Bonus: MHP Bottom Banner',
    };

    const getCustomCategory = () => (
      bonusItem.type === 'rolling' ? 'Rolling Bonus: MHP Placement' : customCategory[instanceType]
    );

    const getCustomAction = () => (
      bonusItem.type === 'rolling' ? 'MHP Placement View' : 'View'
    );

    return {
      category: getCustomCategory(),
      action: getCustomAction(),
      label: bonusItem.id,
    };
  },
};

export const bonusHomeBannerClickAndHoverEvents = [
  {
    selector: '.mn_bonusHomeBanner .mn_termsButton',
    click: {
      category: 'Rolling Bonus: MHP Placement',
      action: 'MHP Placement terms click',
      value: 1,
    },
  },
  {
    selector: '.mn_mhpBonusTopBanner a',
    click: {
      category: 'Automatic Public Bonus: MHP Hero Banner',
      action: 'Click "Shop" CTA',
    },
    label: (element) => {
      const { id } = element.dataset;

      return `${id}`;
    },
  },

  {
    selector: '.mn_mhpBonusBottomBanner a',
    click: {
      category: 'Automatic Public Bonus: MHP Bottom Banner',
      action: 'Click "Shop" CTA',
    },
    label: (element) => {
      const { id } = element.dataset;

      return `${id}`;
    },
  },

  {
    selector: '.mn_vhpBonusTopBanner a',
    click: {
      category: 'Automatic Public Bonus: VHP Hero Banner',
      action: 'Click "Shop" CTA',
    },
    label: (element) => {
      const { id } = element.dataset;

      return `${id}`;
    },
  },

  {
    selector: '.mn_vhpBonusBottomBanner a',
    click: {
      category: 'Automatic Public Bonus: VHP Bottom Banner',
      action: 'Click "Shop" CTA',
    },
    label: (element) => {
      const { id } = element.dataset;

      return `${id}`;
    },
  },
];
