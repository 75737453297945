import { combineReducers } from 'redux';
import dynamicCardLinksReducer from './dynamicCardLinksReducer';
import dynamicCardManagesReducer from './dynamicCardManagesReducer';
import instoreOffersReducer from './instoreOffersReducer';
import instoreOffersTitleReducer from './instoreOffersTitleReducer';
import paymentCardsReducer from './paymentCardsReducer';
import tokenReducer from './tokenReducer';
import instoreLocations from './instoreLocations';
import config from '../configs';

export default combineReducers({
  config: () => config,
  dynamicCardLinksReducer,
  dynamicCardManagesReducer,
  instoreOffersReducer,
  instoreOffersTitleReducer,
  paymentCardsReducer,
  tokenReducer,
  instoreLocations,
});
