import React from 'react';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import Warning from 'core/modules/Instore2/partials/Warning/Warning';

export function LinkWarning({ hasError, feedbackData }) {
  const { deleteCardLink } = feedbackData?.options || {};
  const actionType = deleteCardLink ? 'unlink' : 'link';

  return (
    <Warning hasError={hasError}>
      Sorry, we were unable to {actionType} your card(s). Please try again or check back later
    </Warning>
  );
}

export default (props) => <ComponentErrorBoundary><LinkWarning {...props} /></ComponentErrorBoundary>;
