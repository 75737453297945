import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import { selectSiteUrl } from 'core/selectors/app';
import { useGetCategories } from 'core/modules/Navigation/NavCategories/hooks/useGetCategories';

import NavCategoryTOF from './components/NavCategoryTOF/NavCategoryTOF';
import { triggerNavCategoriesTOFItemView } from '../../actions';
import config from './configs';

import './NavCategoriesTOF.scss';

export function NavCategoriesTOF() {
  const dispatch = useDispatch();
  const timerRef = useRef(null);
  const siteUrl = useSelector(selectSiteUrl);
  const { categories } = useGetCategories();
  const { secTitle, allStoresLinkText } = config;

  // Show navigation with 1 second delay on first hover and show immediately on other hovers
  const [isDropdownDelayPassed, setIsDropdownDelayPassed] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  useEffect(() => {
    if (!isNull(selectedIndex) && isDropdownDelayPassed) {
      dispatch(triggerNavCategoriesTOFItemView(categories[selectedIndex].name));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex, isDropdownDelayPassed]);

  const clearTimer = () => clearTimeout(timerRef.current);

  const triggerDropdownDelayIsPassed = () => {
    clearTimer();
    setIsDropdownDelayPassed(true);
  };

  const triggerDropdownDelayIsNotPassed = () => {
    clearTimer();
    setIsDropdownDelayPassed(false);
  };

  const triggerDropdownDelayIsPassedAfterDelay = () => {
    clearTimer();
    timerRef.current = setTimeout(() => setIsDropdownDelayPassed(true), 1000);
  };

  const showDropdown = (ignoreTimeout, selectedIndexParam) => {
    ignoreTimeout && triggerDropdownDelayIsPassed();
    setSelectedIndex(selectedIndexParam);
  };

  const hideDropdown = () => setSelectedIndex(null);

  if (isEmpty(categories)) {
    return (null);
  }

  return (
    <div className="mn_navCategoriesTOFSection">
      <h2>{secTitle}</h2>
      <a className="mn_allStoresLink mn_button" href={`${siteUrl}/b____.htm`}>{allStoresLinkText}</a>
      <section className="mn_catList mn_dropdownContentWrap">
        <ul
          className="mn_navDropdownMenu"
          onMouseEnter={triggerDropdownDelayIsPassedAfterDelay}
          onMouseLeave={triggerDropdownDelayIsNotPassed}
        >
          {categories.map((category, index) => (
            <NavCategoryTOF
              key={category.id}
              title={category.name}
              label={`Visit the ${category.name} page`}
              url={`${siteUrl}/${category.searchFriendlyName}/bc_${category.id}___.htm`}
              featured={category.featured}
              flyout={category.flyout}
              showDropdown={(e, ignoreTimeout) => showDropdown(ignoreTimeout, index)}
              hideDropdown={() => hideDropdown(index)}
              isFocused={selectedIndex === index && isDropdownDelayPassed}
            />
          ))}
        </ul>
      </section>
    </div>
  );
}

export default NavCategoriesTOF;
