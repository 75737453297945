import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { postInstoreCardLink } from 'core/actions/member';
import { clearInstoreLinkFailure } from 'core/modules/Instore2/actions';
import { selectEasyLinkSuppression } from 'core/selectors/user';
import { selectDynamicCardLinksFeedback } from 'core/modules/Instore2/selectors';
import { useApiFeedbackManager } from 'core/hooks/useApiFeedbackManager';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import { INSTORE_OFFER_MODAL, ME_PAGE_INSTORE_ONLY } from 'core/modules/Instore2/utils/constants';
import {
  LinkWarning, Cards, Logo, Disclaimer, ManageCardsButton, UnlinkConfirmation,
  useUnlinkOfferButton,
} from 'core/modules/Instore2/partials';
import { InstoreOfferModalTitle, InstoreOnlyPill } from '../partials';

import '../InstoreOfferDetails.scss';

function WithLinkedCardsView({ offer, isModal }) {
  const dispatch = useDispatch();
  const {
    displayRate, currency, rclonMerchantName, daysToExpire,
    disclaimer, offerId, hasEligibleCards, eligibleCards,
  } = offer;
  const initiator = isModal ? INSTORE_OFFER_MODAL : ME_PAGE_INSTORE_ONLY;

  const easyLinkSuppression = useSelector(selectEasyLinkSuppression);
  const dynamicCardLinksFeedback = useSelector(selectDynamicCardLinksFeedback);

  const {
    UnlinkOfferButton, shouldShowConfirm, showConfirmMessage, hideConfirmMessage,
  } = useUnlinkOfferButton(initiator, rclonMerchantName);

  const { clearFeedback, feedbackData } = useApiFeedbackManager({
    data: dynamicCardLinksFeedback,
    callbackAction: clearInstoreLinkFailure,
  });

  const { hasLinkFailedFeedback } = feedbackData;

  useEffect(() => {
    if (!easyLinkSuppression && hasEligibleCards) {
      // autolink unlinked cards
      eligibleCards.forEach(({ cardId }) => {
        dispatch(postInstoreCardLink(offerId, { cardId }, {}, { autoLink: true, initiator, offer }));
      });
    }
  }, [easyLinkSuppression, hasEligibleCards]);

  return (
    <div className="mn_instoreOfferDetails mn_withLinkedCardsView">
      {!isModal && <InstoreOnlyPill />}
      {!isModal && <Logo offer={offer} />}
      <InstoreOfferModalTitle offer={offer} />
      <div id="mn_dialogDescription" className="mn_offerInfo">
        {daysToExpire} day{daysToExpire === 1 ? '' : 's'} left
        to earn {displayRate} {currency} in-store at {rclonMerchantName}
      </div>
      <LinkWarning hasError={hasLinkFailedFeedback} feedbackData={feedbackData} />
      <Cards offer={offer} clearWarning={clearFeedback} initiator={initiator} />
      <div className="mn_buttons">
        <ManageCardsButton />
        <span className="mn_divider">|</span>
        <UnlinkOfferButton onClickCallback={showConfirmMessage} className="mn_basicButton mn_unlinkOfferButton" />
      </div>
      <UnlinkConfirmation
        shouldShowConfirm={shouldShowConfirm}
        offer={offer}
        onHideClick={hideConfirmMessage}
        onUnlinkClick={clearFeedback}
        initiator={initiator}
      />
      <Disclaimer disclaimer={disclaimer} />
    </div>
  );
}

export default (props) => <ComponentErrorBoundary><WithLinkedCardsView {...props} /></ComponentErrorBoundary>;
