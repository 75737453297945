/*
  This hook is used to programatically trigger closing of the CanJs modal
*/

import { useEffect } from 'react';

export const useCloseCanJsModal = () => {
  useEffect(() => {
    const isCanJs = window.mn?.brandId;

    if (isCanJs) {
      const element = document.querySelector('.mn_offerOverlay .mn_closeLightbox');
      element?.click();
    }
  }, []);
};
