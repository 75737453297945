import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from 'core/modules//Partials/Loader/Loader';
import { deleteInstoreOfferCardLink } from 'core/actions/member';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import { selectDynamicCardIsOngoingLinking } from 'core/modules/Instore2/selectors';

import './UnlinkConfirmation.scss';

function UnlinkConfirmation({
  shouldShowConfirm, offer, onHideClick, onUnlinkClick, initiator,
}) {
  const { offerId } = offer;
  const dispatch = useDispatch();
  const isOngoingLinking = useSelector(state => selectDynamicCardIsOngoingLinking(state, offerId));

  const unlinkCards = () => {
    onUnlinkClick?.();
    dispatch(deleteInstoreOfferCardLink(offerId, {}, { initiator, offer }));
  };

  if (!shouldShowConfirm) {
    return null;
  }

  return (
    <Loader isLoading={isOngoingLinking}>
      <div className="mn_instoreUnlinkConfirmation">
        <div className="mn_confirmMessage">
          Are you sure you want to unlink this offer from all cards?
        </div>
        <div className="mn_confirmButtons mn_noWrap">
          <button className="mn_basicButton" onClick={unlinkCards}>Yes</button>
          <span className="mn_divider">|</span>
          <button className="mn_basicButton" onClick={onHideClick}>No</button>
        </div>
      </div>
    </Loader>
  );
}

export default (props) => <ComponentErrorBoundary><UnlinkConfirmation {...props} /></ComponentErrorBoundary>;
