import {
  SIMPLE_BANNER_LIST_GET_SUCCESS, SIMPLE_BANNER_LIST_GET_FAILURE,
} from '../actions/types';

import simpleBannerListConfig from '../configs';

const initialState = {
  config: simpleBannerListConfig,
  defaults: {
    isLoaded: false,
    isLoading: false,
    data: [],
  },
};

const simpleBannerList = (state = initialState, action) => {
  const namespaceId = action?.options?.namespace || action?.context?.options?.namespace || 'default';
  const namespace = `namespace-${namespaceId}`;
  const { defaults } = state;
  const { [namespace]: namespaceState = defaults } = state;

  switch (action.type) {
    case SIMPLE_BANNER_LIST_GET_SUCCESS: {
      const { response } = action.payload;

      return {
        ...state,
        [namespace]: {
          ...namespaceState,
          data: response,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case SIMPLE_BANNER_LIST_GET_FAILURE: {
      return {
        ...state,
        [namespace]: {
          ...namespaceState,
          data: [],
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    default: {
      const { options = {} } = action;
      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === SIMPLE_BANNER_LIST_GET_SUCCESS) {
        return {
          ...state,
          [namespace]: {
            ...namespaceState,
            isLoading: true,
          },
        };
      }

      return state;
    }
  }
};

export default simpleBannerList;
