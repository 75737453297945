import {
  MEMBER_DELETE_INSTORE_PAYMENT_CARD,
  MEMBER_DELETE_INSTORE_PAYMENT_CARD_SUCCESS, MEMBER_DELETE_INSTORE_PAYMENT_CARD_FAILURE,
} from 'core/actions/member';

import { INSTORE_FORCE_UPDATE, INSTORE_PAYMENT_CARD_CLEAR_FAILS } from 'core/modules/Instore2/actions/types';

const filterOngoingDeletes = (state, action) => {
  const { cardId } = action.context.data;
  return state.ongoingDeletes.filter((ongoingDelete) => ongoingDelete.cardId !== cardId);
};

const handleFailResponse = (action) => {
  const { data = {}, options = {} } = action.context;
  return { cardId: data.cardId, cardLastFour: options.cardLastFour };
};

const initialState = {
  deletedCards: [],
  ongoingDeletes: [],
  feedback: null,
  isLoading: false,
  isLoaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MEMBER_DELETE_INSTORE_PAYMENT_CARD_SUCCESS: {
      const deletedCard = action.context.data.cardId;

      return {
        ...state,
        deletedCards: [...state.deletedCards, deletedCard],
        ongoingDeletes: filterOngoingDeletes(state, action),
        feedback: null,
        isLoading: false,
        isLoaded: true,
      };
    }
    case MEMBER_DELETE_INSTORE_PAYMENT_CARD_FAILURE: {
      return {
        ...state,
        feedback: handleFailResponse(action),
        ongoingDeletes: filterOngoingDeletes(state, action),
        isLoading: false,
        isLoaded: true,
      };
    }
    case MEMBER_DELETE_INSTORE_PAYMENT_CARD: {
      return {
        ...state,
        ongoingDeletes: [...state.ongoingDeletes, action.data],
        isLoading: true,
        isLoaded: false,
      };
    }
    case INSTORE_PAYMENT_CARD_CLEAR_FAILS: {
      return {
        ...state,
        feedback: null,
      };
    }
    case INSTORE_FORCE_UPDATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
