import {
  FEATURED_OFFERS_150X150_GET_FAILURE, FEATURED_OFFERS_150X150_GET_SUCCESS,
} from '../actions/types';

import featuredOffers150X150Config from '../configs';

const initialState = {
  config: featuredOffers150X150Config,
  defaults: {
    isLoaded: false,
    isLoading: false,
    data: [],
  },
};

export default (state = initialState, action) => {
  const namespaceId = action?.options?.namespace || action?.context?.options?.namespace || 'default';
  const namespace = `namespace-${namespaceId}`;
  const { defaults } = state;
  const { [namespace]: namespaceState = defaults } = state;

  switch (action.type) {
    case FEATURED_OFFERS_150X150_GET_SUCCESS: {
      const { response = [] } = action.payload;

      return {
        ...state,
        [namespace]: {
          ...namespaceState,
          data: response || [],
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case FEATURED_OFFERS_150X150_GET_FAILURE: {
      return {
        ...state,
        [namespace]: {
          ...namespaceState,
          data: [],
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    default: {
      const { options = {} } = action;
      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === FEATURED_OFFERS_150X150_GET_SUCCESS) {
        return {
          ...state,
          [namespace]: {
            ...namespaceState,
            isLoading: true,
          },
        };
      }

      return state;
    }
  }
};
