import React from 'react';

import './InstoreRebate.scss';

function InstoreRebate({ offer, prefix = 'Earn' }) {
  const { displayRate, currency } = offer;

  return (
    <p className="mn_instoreRebate">
      {prefix} {displayRate} {currency} <span className="mn_suffix">in-store</span>
    </p>
  );
}

export default InstoreRebate;
