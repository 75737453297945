import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { getId } from 'core/utils/analytics/sectionInfo';

import { selectActiveMQ, selectIsMobileMQ } from 'core/modules/MediaQueries/selectors';
import Modal from 'core/modules/Modal/Modal';
import FavoriteIcon from 'core/modules/FavoriteIcon/FavoriteIcon';
import PremierMerchantRebate from 'core/modules/PremierMerchantRebate/PremierMerchantRebate';
import MerchantExperienceLink from 'core/modules/MerchantExperienceLink/MerchantExperienceLink';
import MerchantClickUrlLink from 'core/modules/MerchantClickUrlLink/MerchantClickUrlLink';
import MerchantShopNowLinkLabel from 'core/modules/MerchantShopNowLinkLabel/MerchantShopNowLinkLabel';
import ExtraRewardsPill from 'core/modules/ExtraRewardsPill/ExtraRewardsPill';
import { ShowOnMQ } from 'core/modules/MediaQueries/MediaQueries';
import { useDefineInitialParams } from 'core/hooks/useDefineInitialParams';

import './MerchantOffer.scss';

function MerchantOffer({
  isUserIdentified, merchant, sectionId, selectedOffer,
  customClass, historyUpdated, previousHash, config, shouldTrackInitialParams,
}) {
  const [isCodeCopied, setIsCodeCopied] = useState(false);
  const isMobileMQ = useSelector(selectIsMobileMQ);
  const activeMQ = useSelector(selectActiveMQ);

  const { rebate, showRebate, name } = merchant;
  const {
    rebateOptions, getCopyButtonText, getCopyButtonDisclaimerText, tagHelper, modalOptions,
    getExpiresText, useRebateInlineOnMediaQueriesOnly, shouldRenderDisclaimerInsideDescription,
    showExtraRewardsPill,
  } = config;

  const showRebateInline = useRebateInlineOnMediaQueriesOnly.includes(activeMQ);

  const copyButtonText = getCopyButtonText(isMobileMQ);
  const expiresText = getExpiresText(isMobileMQ);
  const copyButtonDisclaimerText = getCopyButtonDisclaimerText(isMobileMQ);

  const handleCopyBtn = () => {
    if (!isCodeCopied) {
      setIsCodeCopied(true);
    }
  };

  const merchantRebateDisclaimer = get(merchant, 'rebate.disclaimer');
  const offerDisclaimer = get(selectedOffer, 'disclaimer');
  const offerClickUrl = get(selectedOffer, 'clickUrl');
  const hasDisclaimer = !isEmpty(merchantRebateDisclaimer) || !isEmpty(offerDisclaimer);

  // Show "offerDetails" field only if content item type ID# 49,
  // for all other content item types show the "description" field
  const shouldShowOfferDetails = selectedOffer?.contentItemTypeId === 49;
  const description = selectedOffer &&
    (shouldShowOfferDetails ? selectedOffer.offerDetails : selectedOffer.description);
  const trackingParams = useDefineInitialParams(shouldTrackInitialParams, { sectionId });
  const renderDisclaimer = () => (
    <div className="mn_olayDisclaimer">
      {offerDisclaimer}
      {merchantRebateDisclaimer}
    </div>
  );

  const handleRef = useCallback((ref) => {
    tagHelper(ref, getId('home-featured')); // handle Chase tagging implementation
  }, []);

  return (
    <Modal
      className={customClass}
      historyUpdated={historyUpdated}
      previousHash={previousHash}
      options={modalOptions}
      testId="merchant-offer-overlay"
    >
      <div className="mn_lightboxDecoration" />
      <div className="mn_lightBoxContent" ref={handleRef}>
        <div id="mn_dialogTitle" className="mn_sr-only">
          Dialog with offer details for {merchant.name}
        </div>
        <div className={`mn_merchantOfferOverlayContent ${selectedOffer ? 'mn_offerOverlayContent' : ''}`}>

          <div className="mn_olayMerchantLogo">
            {isUserIdentified && <FavoriteIcon merchantId={merchant.id} merchantName={merchant.name} />}
            <img src={merchant.logoUrls._120x60} alt={merchant.name} />
          </div>

          {showExtraRewardsPill && <ShowOnMQ mobile><ExtraRewardsPill rebate={rebate} /></ShowOnMQ>}

          <div className="mn_olayDescription" id="mn_dialogDescription">
            { description &&
              <h2
                className="mn_offerTitle"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            }

            { shouldRenderDisclaimerInsideDescription && hasDisclaimer && renderDisclaimer() }

            { (selectedOffer && !selectedOffer.isOngoing) &&
              <span className="mn_expires">{expiresText} {selectedOffer.expires}</span>
            }

            <hr />

            { rebate &&
              (showRebate ?
                <PremierMerchantRebate
                  {...rebate}
                  {...rebateOptions}
                  showRebateInline={showRebateInline}
                />
                : <div className="mn_merchantNoRebate">{name}</div>
              )
            }

          </div>

          { !shouldRenderDisclaimerInsideDescription && hasDisclaimer && renderDisclaimer() }

          { selectedOffer && selectedOffer.code &&
            <div className="mn_olayCoupon">
              <div className="mn_olayCouponControls">
                <label htmlFor="mn_couponCodeOffer">Coupon code
                  <input
                    className="mn_couponCode"
                    id="mn_couponCodeOffer"
                    type="text"
                    value={selectedOffer.code}
                    readOnly
                  />
                </label>
                <CopyToClipboard text={selectedOffer.code}>
                  <button
                    className={`mn_copyCouponCode ${isCodeCopied ? 'mn_codeIsCopied' : ''}`}
                    onClick={() => handleCopyBtn()}
                  >
                    {isCodeCopied ? 'Copied' : copyButtonText}
                  </button>
                </CopyToClipboard>
              </div>
              <p>{copyButtonDisclaimerText}</p>
            </div>
          }

          <div className="mn_olayCta">
            <MerchantClickUrlLink
              offerClickUrl={offerClickUrl}
              className="mn_olayShopNow"
              merchant={merchant}
              data-section-id={sectionId}
            >
              {isUserIdentified
                ? <MerchantShopNowLinkLabel merchant={merchant} />
                : <>Sign in to <MerchantShopNowLinkLabel merchant={merchant} /></>
              }
            </MerchantClickUrlLink>
          </div>

          <div className="mn_olayFooter">
            { selectedOffer &&
              <p className="mn_olayAllOffers">
                <MerchantExperienceLink
                  merchant={merchant}
                  className="mn_arrowLink"
                  trackingParams={trackingParams}
                  shouldIncludeKeywords
                >
                  {`View all ${merchant.name} offers (${merchant.offersCount})`}
                </MerchantExperienceLink>
              </p>
            }

            <MerchantExperienceLink
              merchant={merchant}
              urlHash="merchantDisclaimer"
              className="mn_olayMerchantTerms"
              trackingParams={trackingParams}
              shouldIncludeKeywords
            >
              Store terms and conditions
            </MerchantExperienceLink>
          </div>
        </div>
      </div>
    </Modal>
  );
}

MerchantOffer.propTypes = {
  isUserIdentified: PropTypes.bool.isRequired,
  merchant: PropTypes.shape({}).isRequired,
  customClass: PropTypes.string,
  historyUpdated: PropTypes.bool,
  previousHash: PropTypes.string,
  selectedOffer: PropTypes.shape({}),
};

MerchantOffer.defaultProps = {
  customClass: null,
  historyUpdated: null,
  previousHash: null,
  selectedOffer: null,
};

export default MerchantOffer;
