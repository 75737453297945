import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { dynamicMerchant as orgConfig } from 'org/configs/modules.config';
import { RANDOM, RECOMMENDED } from 'core/modules/DynamicMerchants/utils/sortTypes';
import { DYNAMIC_MERCHANTS_GET_SUCCESS, DYNAMIC_MERCHANTS_GET_FAILURE } from '../actions/types';

const defaultConfig = {
  defaults: {
    api: {
      options: {
        success: DYNAMIC_MERCHANTS_GET_SUCCESS,
        failure: DYNAMIC_MERCHANTS_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
        sectionId: 10225,
      },
      params: {
        sort_type: 'asc',
        sort_by: RECOMMENDED,
        content_type_id: 58,
        fields: [
          'id',
          'assets',
          'clickUrl',
          'merchant.id',
          'merchant.logoUrls',
          'merchant.rebate',
          'merchant.name',
          'merchant.showRebate',
          'merchant.offersCount',
          'merchant.categories',
        ],
      },
    },
    minMerchantsForSort: 13,
    checkShouldShowMenu: (shouldUseCarousel, merchantsNumberOnInitialLoad, minMerchantsForSort) => (
      !shouldUseCarousel && merchantsNumberOnInitialLoad >= minMerchantsForSort
    ),
    carousel: {
      breakpoints: {
        l: { hasDots: false, hasArrows: true },
      },
    },
    getExtraLabel: (isMobileMQ) => (isMobileMQ ? 'Extra miles' : 'Extra'),
  },
  sweeps: {
    api: {
      params: {
        offer_tag: 'sweeps-merchant-module-small-logo',
        sort_by: RANDOM,
        sort_type: null,
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
