import React from 'react';

import defaultBrowserLogoImage from 'core/assets/images/button/chrome.svg';
import defaultStoreRatingImage from 'core/assets/images/button/5stars.svg';

import './ChromeRating.scss';

function ChromeRating({
  chromeText, browserLogoImage = defaultBrowserLogoImage, storeRatingImage = defaultStoreRatingImage,
}) {
  const defaultChromeDescription = 'Chrome store rating:';

  return (
    <div className="mn_buttonRating">
      <img className="mn_browserLogo" src={browserLogoImage} alt="Chrome" />
      <span className="mn_ratingText">{chromeText || defaultChromeDescription}</span>
      <img className="mn_stars" src={storeRatingImage} alt="5 stars" />
    </div>
  );
}

export default ChromeRating;
