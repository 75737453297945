import { getParamsFromSearch } from './getParamsFromSearch';
/**
 * constructs call back URL for Lightbox Overlay URL
 * @returns {String} Callback URL
 */
export function getCallBackUrl(url, customCallbackParam = null) {
  try {
    const params = getParamsFromSearch(url);
    const sessionBase64EncodeValue = params.session;
    const decodedSessionParam = window.atob(sessionBase64EncodeValue);
    const callBackParam = customCallbackParam || (decodedSessionParam + window.location.hash);
    const encodedSessionParam = window.btoa(callBackParam);
    const decodedCallBackUrl = url.replace(sessionBase64EncodeValue, encodedSessionParam);

    return decodedCallBackUrl;
  } catch (error) {
    return url;
  }
}
