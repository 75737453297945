/*
  This component manages Alaska Instore login logic and is added as a temporary solution
  to be able to procced with Alaska Instore implementation. Due to development time needs and to reduce
  the testing scope, all changes are encapsulated in this wrapper component so we don't need to retest
  all core login-related functionality at this point in time. A subsequent ticket will address
  the refactoring of the original core `LoginLink` component to integrate all these adjustments
  comprehensively, including the `useBase64CallBackURL` feature for United.
*/

import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import LoginLink from 'core/modules/LoginLink/LoginLink';
import { selectLoginUrl } from 'core/selectors/app';
import { getCallBackUrl } from 'core/utils/helpers/buildCallbackURL';
import { selectCookieDomain, selectIsHttps } from 'core/selectors/env';
import config from './configs';

function InstoreLoginLink({
  title = 'Sign in', className = '', shouldIncludeHash, children,
}) {
  const cookieDomain = useSelector(selectCookieDomain);
  const isHttps = useSelector(selectIsHttps);
  const location = useLocation();
  const loginUrl = useSelector(selectLoginUrl);

  const { useBase64CallBackURL, useCallbackCookie } = config;

  const loginClickCallback = () => {
    if (useCallbackCookie) {
      Cookies.set('mn_callback', window.location.href, {
        path: '/',
        domain: cookieDomain,
        secure: isHttps,
      });
    }
  };

  const urlWithHash =
    useBase64CallBackURL ? getCallBackUrl(loginUrl) : `${loginUrl}${encodeURIComponent(location.hash)}`;

  const url = shouldIncludeHash ? urlWithHash : undefined;

  return (
    <LoginLink url={url} title={title} className={className} clickCallback={loginClickCallback}>
      {children}
    </LoginLink>
  );
}

export default InstoreLoginLink;
