import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { selectUserIsIdentified } from 'core/selectors/user';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import { useGetInstoreOffers } from 'core/modules/Instore2/hooks/useGetInstoreOffers';
import {
  useTriggerInstoreDetailsIsViewedGaEvent,
} from 'core/modules/Instore2/hooks/useTriggerInstoreDetailsIsViewedGaEvent';
import { INSTORE_OFFER_MODAL, INSTORE_EASY_LINK_MODAL } from 'core/modules/Instore2/utils/constants';
import EasyLinkModal from './components/EasyLinkModal';
import EasyLinkWithoutAnyCardsModal from './components/EasyLinkWithoutAnyCardsModal';
import AnonymousModal from './components/AnonymousModal';
import WithLinkedCardsModal from './components/WithLinkedCardsModal';
import WithoutLinkedCardsModal from './components/WithoutLinkedCardsModal';
import WithoutAnyCardsModal from './components/WithoutAnyCardsModal';

import '../InstoreModal.scss';

function InstoreOfferModal({ modal, closeModal }) {
  const { params } = modal;
  const {
    offerId, namespace, error, easyLink, wasAlreadyLinked, noAnyCards,
  } = params;
  const isUserIdentified = useSelector(selectUserIsIdentified);

  const { offers } = useGetInstoreOffers({ namespace });

  const getCurrentOffer = () => offers.find((offer) => Number(offer.offerId) === Number(offerId));
  const offer = getCurrentOffer();

  const initiator = easyLink ? INSTORE_EASY_LINK_MODAL : INSTORE_OFFER_MODAL;
  useTriggerInstoreDetailsIsViewedGaEvent(offer, initiator, params);

  if (isEmpty(offer)) {
    return null;
  }

  const {
    hasEligibleCards, hasLinkedCards, carteraMerchantId, rclonMerchantName,
  } = offer;

  const attributes = {
    'data-gmid': carteraMerchantId,
    'data-merchant-name': rclonMerchantName,
    'data-offer-id': offerId,
  };

  if (easyLink) {
    return noAnyCards
      ? <EasyLinkWithoutAnyCardsModal offer={offer} attributes={attributes} />
      : <EasyLinkModal offer={offer} wasAlreadyLinked={wasAlreadyLinked} closeModal={closeModal} />;
  }

  if (!isUserIdentified) {
    return <AnonymousModal offer={offer} />;
  }

  if (hasLinkedCards) {
    return <WithLinkedCardsModal offer={offer} attributes={attributes} />;
  }

  if (hasEligibleCards && !hasLinkedCards) {
    return <WithoutLinkedCardsModal offer={offer} error={error} attributes={attributes} />;
  }

  return <WithoutAnyCardsModal offer={offer} />;
}

export default (props) => <ComponentErrorBoundary><InstoreOfferModal {...props} /></ComponentErrorBoundary>;
