import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { onboardingButton as orgConfig } from 'org/configs/modules.config';

import chromeSVG from 'core/modules/Onboarding/assets/images/chrome.svg';
import starsSVG from 'core/modules/Onboarding/assets/images/5stars.svg';
import appleSVG from 'core/modules/Onboarding/assets/images/apple-icon.svg';

/** @type {object} */
const defaultConfig = {
  defaults: {
    viewData: {
      getButtonHeader: () => ('button'),
      getButtonDescription: () => '',
      rewardsType: () => ('miles'),
      usePeriod: false,
      secondBulletText: 'Automatically apply coupons at checkout',
      customThirdBulletText: 'Find new stores and get the lowest price',
      chromeSvg: chromeSVG,
      starsSvg: starsSVG,
      mobileButtonHeader: '',
      mobileButtonDescription: '',
      mobileButtonAppleImg: appleSVG,
      mobileButtonCTA: '',
      mobileButtonLaterText: '',
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
