/*
  This hook consolidates in-store related hooks that are listening for the app state changes
  and trigger appropriate modal if needed
*/
import { useHandleInstorEasyLinkModal } from './useHandleInstorEasyLinkModal';
import { useHandleCardLinkFailModal } from './useHandleCardLinkFailModal';

export const useHandleInstoreModals = () => {
  useHandleInstorEasyLinkModal();
  useHandleCardLinkFailModal();
};
