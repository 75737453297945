/*
  This hook is used to fetch user's payment cards information
*/

import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getInstorePaymentCards } from 'core/actions/member';
import { selectPaymentCards, selectPaymentCardsIsLoaded, selectPaymentCardsIsLoading } from '../selectors';

export const useGetPaymentCards = () => {
  const dispatch = useDispatch();
  const cards = useSelector(selectPaymentCards);
  const isLoaded = useSelector(selectPaymentCardsIsLoaded);
  const isLoading = useSelector(selectPaymentCardsIsLoading);

  useEffect(() => {
    if (!isLoaded && !isLoading) {
      dispatch(getInstorePaymentCards());
    }
  }, [isLoaded, isLoading]);

  return {
    cards,
    isLoaded,
    isLoading,
  };
};
