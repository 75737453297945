import {
  INSTORE_AD_OFFERS_GET_FAILURE, INSTORE_AD_OFFERS_GET_SUCCESS, INSTORE_AD_OFFERS_CONTENT_GROUP_ID,
} from '../actions/types';

import instoreAdConfig from '../configs';

const initialState = {
  config: instoreAdConfig,
  isLoading: false,
  isLoaded: false,
  offers: [],
};

const instoreAdOffers = (state = initialState, action) => {
  switch (action.type) {
    case INSTORE_AD_OFFERS_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        offers: action.payload.response,
      };
    }
    case INSTORE_AD_OFFERS_CONTENT_GROUP_ID: {
      return {
        ...state,
        contentGroupIdInfo: action.data,
      };
    }
    case INSTORE_AD_OFFERS_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        offers: [],
      };
    }
    default: {
      const { options = {} } = action;

      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === INSTORE_AD_OFFERS_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }
    }
  }

  return state;
};

export default instoreAdOffers;
