import {
  INSTORE_LOCATION_OFFERS_GET_SUCCESS, INSTORE_LOCATION_OFFERS_GET_FAILURE,
} from 'core/modules/Instore2/actions/types';

const initialState = {
  locations: [],
  isLoading: false,
  isLoaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INSTORE_LOCATION_OFFERS_GET_SUCCESS: {
      return {
        ...state,
        locations: action.payload.response,
        isLoading: false,
        isLoaded: true,
      };
    }
    case INSTORE_LOCATION_OFFERS_GET_FAILURE: {
      return {
        ...state,
        locations: [],
        isLoading: false,
        isLoaded: true,
      };
    }
    default: {
      const { options = {} } = action;
      if (options.success === INSTORE_LOCATION_OFFERS_GET_SUCCESS) {
        return { ...state, isLoading: true };
      }

      return state;
    }
  }
};
