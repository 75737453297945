import get from 'lodash/get';
import merge from 'lodash/merge';
import {
  DYNAMIC_REWARDS_CONTENT_GET_SUCCESS,
  DYNAMIC_REWARDS_CONTENT_GET_FAILURE,
} from '../actions/types';
import dynamicRewardsPageConfig from '../configs';

const initialState = {
  config: dynamicRewardsPageConfig,
  isLoaded: false,
  isLoading: false,
};

const dynamicRewardsPageContent = (state = initialState, action) => {
  switch (action.type) {
    case DYNAMIC_REWARDS_CONTENT_GET_SUCCESS: {
      const response = action.payload.response[0];
      return {
        ...state,
        // BonusOffers are temporary mocked into response
        // pageContent: response,
        pageContent: merge({}, response, {
          slots: [
            {
              components: get(response, 'slots[0].components'),
            },
          ],
        }),
        isLoading: false,
        isLoaded: true,
      };
    }
    case DYNAMIC_REWARDS_CONTENT_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
      };
    }
    default: {
      const { options = {} } = action;
      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === DYNAMIC_REWARDS_CONTENT_GET_SUCCESS) {
        return { ...state, isLoading: true };
      }

      return state;
    }
  }
};

export default dynamicRewardsPageContent;
