import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { featuredOffers120x90 as orgConfig } from 'org/configs/modules.config';
import {
  FEATURED_OFFERS_120X90_GET_SUCCESS, FEATURED_OFFERS_120X90_GET_FAILURE,
} from '../actions/types';

const defaultConfig = {
  defaults: {
    useAddToFavorite: false,
    api: {
      options: {
        success: FEATURED_OFFERS_120X90_GET_SUCCESS,
        failure: FEATURED_OFFERS_120X90_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
        sectionId: 9753,
      },
      params: {
        sort_by: 'random',
        with_text_offers: 1,
        group_by_merchant: 0,
        fields: [
          'id',
          'assets',
          'clickUrl',
          'merchant',
          'offerTitle',
          'offerDetails',
          'offerText',
        ].join(','),
      },
    },
    carousel: {},
  },
  b: {
    useAddToFavorite: true,
    api: {
      params: {
        group_by_merchant: 1,
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
