/*
  These hooks are used to fetch in-store offers

  `useGetInstoreOffers` is a primary hook used to fetch in-store offers based on the `namespace`

  `useGetAllInstoreOffers` is a wrapper for the `useGetInstoreOffers`.
  It is used to fetch all in-store offers (from `in-store/offers` endpoint) that are shown on Instore
  page and are used to identify merchants with in-store offers for proper easy linking process.
  By using the `useGetAllInstoreOffers` hook with the same list of parameters (e.g, `sort_by`),
  we can efficiently cache API responses and prevent redundant API calls for identical data.
*/

import { useEffect, useMemo } from 'react';
import { fetchInstoreOffers, fetchPlacements } from 'core/actions/ocapi';
import { useSelector, useDispatch } from 'react-redux';
import { selectUserIsIdentified } from 'core/selectors/user';
import { selectInstoreIsEnabled } from 'core/selectors/app';
import { ALL_INSTORE_OFFERS_ID } from 'core/modules/Instore2/utils/constants';
import {
  selectInstoreConfig, selectDynamicCardHolderId,
  makeSelectDynamicInstoreOffers, makeSelectInstoreOffersIsLoaded, makeSelectInstoreOffersIsLoading,
} from '../selectors';

export const useGetInstoreOffers = ({ ignoreFetching, namespace = '', componentParams = {} }) => {
  const dispatch = useDispatch();
  const isInstoreEnabled = useSelector(selectInstoreIsEnabled);
  const config = useSelector(selectInstoreConfig);
  const { instoreOffers: { api } } = config;
  const { params: configParams, options: configOptions } = api.content;

  const params = {
    ...configParams,
    ...componentParams,
  };

  const options = {
    ...configOptions,
    namespace,
  };

  const selectInstoreOffersIsLoading = useMemo(makeSelectInstoreOffersIsLoading, [namespace]);
  const selectInstoreOffersIsLoaded = useMemo(makeSelectInstoreOffersIsLoaded, [namespace]);
  const selectDynamicInstoreOffers = useMemo(makeSelectDynamicInstoreOffers, [namespace]);

  const isLoading = useSelector(state => selectInstoreOffersIsLoading(state, namespace));
  const isLoaded = useSelector(state => selectInstoreOffersIsLoaded(state, namespace));
  const dynamicOffers = useSelector(state => selectDynamicInstoreOffers(state, namespace));

  const cardholderId = useSelector(selectDynamicCardHolderId);
  const isUserIdentified = useSelector(selectUserIsIdentified);

  useEffect(() => {
    let timerId;
    if (!isInstoreEnabled || ignoreFetching) {
      return () => clearTimeout(timerId);
    }

    if (isUserIdentified && cardholderId) {
      params.applicable_cardholder_id = cardholderId;
    }

    // Move execution to the end of the event queue to defer fetching until after the current render cycle,
    // preventing possible duplicate API calls in cases of rapid state or prop updates.
    timerId = setTimeout(() => {
      if (!isLoaded && !isLoading) {
        params.content_group_id
          ? dispatch(fetchPlacements(params, options))
          : dispatch(fetchInstoreOffers(params, options));
      }
    }, 0);

    return () => clearTimeout(timerId);
  }, [isLoaded, isLoading, isUserIdentified, cardholderId, isInstoreEnabled, ignoreFetching, params, options]);

  return {
    offers: dynamicOffers,
    isLoaded,
    isLoading,
  };
};

export const useGetAllInstoreOffers = ({ componentParams = {}, ignoreFetching } = {}) => {
  const { offers, isLoaded, isLoading } = useGetInstoreOffers({
    ignoreFetching,
    namespace: ALL_INSTORE_OFFERS_ID,
    componentParams: {
      sort_by: 'ranking',
      ...componentParams,
    },
  });

  return {
    offers,
    isLoaded,
    isLoading,
  };
};
