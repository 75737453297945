import { createSelector } from 'reselect';
import { selectConfig } from './app';

export const selectEnv = createSelector(selectConfig, config => config.env);

export const selectServerId = createSelector(selectEnv, env => env.serverId);
export const selectServerDate = createSelector(selectEnv, env => env.serverDate);
export const selectCurrentDate = createSelector(selectEnv, env => env.currentDate);
export const selectPreviewDateTime = createSelector(selectEnv, env => env.previewDateTime);
export const selectActiveCurrentDate = createSelector(
  [selectCurrentDate, selectPreviewDateTime],
  (currentDate, previewDate) => previewDate || currentDate,
);
export const selectIsProductionMode = createSelector(selectEnv, env => env.isProductionMode);
export const selectIsApiCacheEnabled = createSelector(selectEnv, env => env.isApiCacheEnabled);
export const selectRootDomain = createSelector(selectEnv, env => env.rootDomain);
export const selectCookieDomain = createSelector(selectEnv, env => env.cookieDomain);
export const selectUrlPrefix = createSelector(selectEnv, env => env.envUrlPrefix);
export const selectUrlSuffix = createSelector(selectEnv, env => env.envUrlSuffix);
export const selectRevisionHash = createSelector(selectEnv, env => env.revisionHash);
export const selectIsHttps = createSelector(selectEnv, env => env.isHttps);
export const selectIsRavenCookied = createSelector(selectEnv, env => env.isRavenCookied);
export const selectInstoreIframeRclonLink = createSelector(selectEnv, env => env.instoreIframeRclonLink || '');
export const selectSiteProtocol = createSelector(selectIsHttps, isHttps => (
  isHttps ? 'https:' : 'http:'
));
export const selectIsWebView = createSelector(selectEnv, env => env.isWebView);
