import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { loader as orgConfig } from 'org/configs/modules.config';

import loaderCircle from 'core/assets/images/loaderCircle.gif';

const defaultConfig = {
  defaults: {
    loaderImage: loaderCircle,
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
