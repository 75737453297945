import React from 'react';
import { useSelector } from 'react-redux';
import { selectPageName } from 'core/selectors/app';
import { useNavigate } from 'react-router-dom';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import { FAQ_ID } from 'core/modules/Instore2/utils/constants';
import { Cards, DaysToExpire } from 'core/modules/Instore2/partials';
import { EasylinkModalTitle } from '../partials';

import '../InstoreOfferDetails.scss';

function EasyLinkView({ offer, wasAlreadyLinked, closeModal }) {
  const { displayRate, currency, rclonMerchantName } = offer;
  const navigate = useNavigate();
  const pageName = useSelector(selectPageName);

  const onFaqClick = () => {
    const URL = `/instore#${FAQ_ID}`;

    const updateHashToScroll = () => {
      closeModal();
      setTimeout(() => { navigate(URL); }, 100);
    };

    pageName === 'is' ? updateHashToScroll() : window.location.assign(URL);
  };

  return (
    <div className="mn_instoreOfferDetails mn_easyLinkView">
      <div className="mn_successIcon" />
      <EasylinkModalTitle offer={offer} wasAlreadyLinked={wasAlreadyLinked} />
      <div className="mn_offerAndCardsInfo">
        <div id="mn_dialogDescription" className="mn_offerInfo">
          Earn {displayRate} {currency} in-store at {rclonMerchantName}
        </div>
        <Cards offer={offer} />
        <DaysToExpire offer={offer} />
      </div>
      <div className="mn_easyLinkFaq">
        <p>
          If you made a purchase online, we'll continue to track your purchase as usual.
          We'll link in-store offers for you when you click to shop online at store sites with active in-store offers.
        </p>
        <p>
          You can manually remove the link if you'd rather not have this offer linked to your participating card(s).
          Read the <button className="mn_basicButton" onClick={onFaqClick}>FAQs</button> for more information.
        </p>
      </div>
    </div>
  );
}

export default (props) => <ComponentErrorBoundary><EasyLinkView {...props} /></ComponentErrorBoundary>;
