import React from 'react';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import Modal from 'core/modules/Modal/Modal';
import AnonymousView from 'core/modules/Instore2/components/InstoreOfferDetails/components/AnonymousView';

function AnonymousModal({ offer }) {
  return (
    <Modal className="mn_instoreModal mn_instoreOfferModal" options={{ shouldUpdateUrl: true }}>
      <AnonymousView offer={offer} isModal />
    </Modal>
  );
}

export default (props) => <ComponentErrorBoundary><AnonymousModal {...props} /></ComponentErrorBoundary>;
