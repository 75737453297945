import React from 'react';

import './Logo.scss';

function Logo({ offer }) {
  const {
    rclonMerchantName, carteraMerchantLogoUrls: { _88x31, _120x60, svg }, imageUrl,
  } = offer;

  const image = svg || _120x60 || _88x31 || imageUrl;

  if (image) {
    return <div className="mn_instoreLogoWrap"><img src={image} alt={rclonMerchantName} /></div>;
  }

  return <div className="mn_instoreMerchantName">{rclonMerchantName}</div>;
}

export default Logo;
