import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import ReactDOM from 'react-dom';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import * as CoreModals from 'core/modules/Modals';
import * as OrgModals from 'org/modules/Modals';
import { useLocation } from 'react-router-dom';
import { getParamsFromHash } from 'core/utils/helpers/getParamsFromHash';
import globalDyHandler from 'core/utils/helpers/dyHandler';
import { useHandleBonusRollingModal } from 'core/modules/Modals/BonusRollingModal/hooks';
import { useHandleFirstFavoriteModal } from 'core/modules/Modals/FirstFavoriteModal/hooks';
import { useHandleInstoreModals } from 'core/modules/Instore2/hooks/useHandleInstoreModals';
import { useModal } from './ModalProvider';

function ModalRoot() {
  const { modal, openModal, closeModal } = useModal();
  const location = useLocation();
  const hashParams = getParamsFromHash(location.hash);
  const handleBonusRollingModal = useHandleBonusRollingModal();
  const handleFirstFavoriteModal = useHandleFirstFavoriteModal();
  useHandleInstoreModals();

  useEffect(() => {
    const { modalType, ...modalParams } = hashParams;

    if (modalType) {
      openModal({ name: modalType, params: modalParams, options: { wasTriggeredFromUrl: true } });
    }
  }, []);

  useEffect(() => {
    // Global method, which will be triggered from DY campaign
    globalDyHandler.showButtonScrollingOverlay = () => {
      openModal({ name: 'mt_buttonScroll' });
    };

    handleBonusRollingModal();
    handleFirstFavoriteModal();
  }, []);

  if (isEmpty(modal)) {
    return null;
  }

  const Component = OrgModals[modal.name] || CoreModals[modal.name];

  if (!Component) {
    return null;
  }

  return (
    ReactDOM.createPortal(
      <Component modal={modal} openModal={openModal} closeModal={closeModal} />,
      document.getElementById('app'),
    )
  );
}

export default (props) => <ComponentErrorBoundary><ModalRoot {...props} /></ComponentErrorBoundary>;
