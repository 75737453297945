import {
  INSTORE_FORCE_UPDATE, INSTORE_CARD_ADDITION_COMPLETED, INSTORE_CARD_LINK_CLEAR_FAILS,
  INSTORE_PAYMENT_CARD_CLEAR_FAILS, INSTORE_CARD_LINK_CLEAR_EASYLINK_OFFER_ID, INSTORE_NEW_CARD_ADD_FAILED,
  INSTORE_ADD_NEW_CARD_MODAL_VIEWED, INSTORE_ADD_NEW_CARD_MODAL_CLOSED, INSTORE_OFFER_DETAILS_VIEWED,
  INSTORE_NEW_CARD_ADDED, INSTORE_NEW_CARD_POST_MESSAGED, INSTORE_LINK_OFFER_SUCCEEDED, INSTORE_LINK_OFFER_FAILED,
  INSTORE_UNLINK_BUTTON_CLICKED, INSTORE_UNLINK_OFFER_SUCCEEDED, INSTORE_UNLINK_OFFER_FAILED,
  INSTORE_REMOVE_CARD_FAILED, INSTORE_EDUCATIONAL_TILE_VIEWED, INSTORE_OFFER_TILES_VIEWED,
} from './types';

// This action clears the dynamic app state and triggers in-store offers re-fetching
export const triggerInstoreForceUpdate = () => ({
  type: INSTORE_FORCE_UPDATE,
});

// This action is dispatched to reset the state of a failed card linking to the offer
export const clearInstoreLinkFailure = () => ({
  type: INSTORE_CARD_LINK_CLEAR_FAILS,
});

// This action is dispatched after the easylink in-store offer overaly is shown
// and clears the easylinked offerId record in the redux store
export const clearInstoreLinkEasyLinkedOfferId = () => ({
  type: INSTORE_CARD_LINK_CLEAR_EASYLINK_OFFER_ID,
});

// This action handles saga side affects after a new card is added
// (e.g., link new card to offers, clear dynamic app state and re-fetch in-store offers with updated data)
export const triggerOnCardAddedEffects = (data) => ({
  type: INSTORE_CARD_ADDITION_COMPLETED,
  data,
});

// This action is dispatched to reset the state of a failed card deletion
export const clearCardDeleteFailure = (data) => ({
  type: INSTORE_PAYMENT_CARD_CLEAR_FAILS,
  data,
});

/* analytics */
export const triggerAddNewCardModalIsViewedGaEvent = () => ({
  type: INSTORE_ADD_NEW_CARD_MODAL_VIEWED,
});

export const triggerAddNewCardModalIsClosedGaEvent = () => ({
  type: INSTORE_ADD_NEW_CARD_MODAL_CLOSED,
});

export const triggerNewCardIsAddedGaEvent = (offer) => ({
  type: INSTORE_NEW_CARD_ADDED,
  offer,
});

export const triggerNewCardAddIsFailedGaEvent = (event, merchantName) => ({
  type: INSTORE_NEW_CARD_ADD_FAILED,
  event,
  merchantName,
});

export const triggerNewCardPostMessageGaEvent = (event) => ({
  type: INSTORE_NEW_CARD_POST_MESSAGED,
  event,
});

export const triggerInstoreOfferDetailsIsViewedGaEvent = (offer, initiator, params) => ({
  type: INSTORE_OFFER_DETAILS_VIEWED,
  offer,
  initiator,
  params,
});

export const triggerCardLinkFailedGaEvent = (event) => ({
  type: INSTORE_LINK_OFFER_FAILED,
  event,
});

export const triggerCardLinkSuccededGaEvent = (event) => ({
  type: INSTORE_LINK_OFFER_SUCCEEDED,
  event,
});

export const triggerUnlinkButtonIsClickedGaEvent = (initiator, merchantName) => ({
  type: INSTORE_UNLINK_BUTTON_CLICKED,
  initiator,
  merchantName,
});

export const triggerUnlinkSuccededGaEvent = (event) => ({
  type: INSTORE_UNLINK_OFFER_SUCCEEDED,
  event,
});

export const triggerUnlinkFaieldGaEvent = (event) => ({
  type: INSTORE_UNLINK_OFFER_FAILED,
  event,
});

export const triggerRemoveCardFaieldGaEvent = (event) => ({
  type: INSTORE_REMOVE_CARD_FAILED,
  event,
});

export const triggerEducationalTileIsViewed = (isAddCardTile) => ({
  type: INSTORE_EDUCATIONAL_TILE_VIEWED,
  isAddCardTile,
});

export const triggerInstoreOfferTilesIsViewedGaEvent = (offers) => ({
  type: INSTORE_OFFER_TILES_VIEWED,
  offers,
});
