import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { instoreAddNewCard as orgConfig } from 'org/configs/modules.config';

const defaultConfig = {
  defaults: {
    iframeTheme: 'cartera-pc-form',
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
