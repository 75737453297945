import merge from 'lodash/merge';

import { APP_UPDATE, UPDATE_EASY_LINK_SUPPRESION_PROPERTY } from '../actions/types';

const initialState = {};

const app = (state = initialState, action) => {
  switch (action.type) {
    case APP_UPDATE: {
      return merge({}, state, action.payload);
    }
    case UPDATE_EASY_LINK_SUPPRESION_PROPERTY: {
      return merge({}, state, { user: { properties: { easyLinkSuppression: '1' } } });
    }
    default: {
      return state;
    }
  }
};

export default app;
