import React from 'react';

/* Images */
import congratsImage from 'org/assets/images/Circle_Check100x100.png';
import hiwClickImage from 'org/assets/images/hiw/hiw_home_click.png';
import hiwEarnImage from 'org/assets/images/hiw/hiw_home_earn.png';
import hiwExploreImage from 'org/assets/images/hiw/hiw_home_explore.png';
import hiwShopImage from 'org/assets/images/hiw/hiw_home_shop.png';
import homeButtonImage from 'org/assets/images/home_button.png';

// Safari Button Tutorial assets
import clientLogoImage from 'org/assets/images/aa_logo.svg';
import animatedArrow from 'org/assets/safariButtonInstallTutorial/animatedArrow.svg';
import nonSafariIcon from 'org/assets/safariButtonInstallTutorial/nonSafari/nonSafariIcon.svg';
import setPermissionClickImage from 'org/assets/safariButtonInstallTutorial/permissionSetting/clickText.gif';
import activationReminderImage from 'org/assets/safariButtonInstallTutorial/activationReminder/activationReminder.png';
import activationConfirmationVideo from
  'org/assets/safariButtonInstallTutorial/activationReminder/activationConfirmation.mp4';
import couponMagicImage from 'org/assets/safariButtonInstallTutorial/couponFinder/couponMagic.png';
import couponSuccessVideo from 'org/assets/safariButtonInstallTutorial/couponFinder/couponSuccess.mp4';
import priceMagicVideo from 'org/assets/safariButtonInstallTutorial/priceComparison/priceMagic.mp4';
import searchIntegrationVideo from 'org/assets/safariButtonInstallTutorial/searchIntegration/searchIntegration.mp4';
import storeOfferSettingsVideo from 'org/assets/safariButtonInstallTutorial/storeOfferSettings/storeOfferSettings.mp4';

/* Components */
import ButtonScrollingModalContent
  from 'org/modules/Modals/ButtonScrollingModal/components/ButtonScrollingModalContent';

/* Hooks */
import { useGetCreditCardMarketingBanner }
  from 'org/modules/CreditCardMarketingBanner/hooks/useGetCreditCardMarketingBanner';

export const creditCardMarketingBanner = {
  defaults: {
    hooks: {
      useGetCreditCardMarketingBanner,
    },
    shouldShowButtonView: false,
    cardContentGroupIds: {
      cityCardContentGroupId: 34950,
      barclaysCardContentGroupId: 34955,
      cityAndBarclaysCardContentGroupId: 35229,
    },
  },
};

export const searchBar = {
  defaults: {
    getPlaceholder: () => ('Find a store or product'),
    shouldUseHybridSearch: true,
  },
};

export const hybridSearchForm = {
  defaults: {
    searchButtonClassName: 'mn_icon',
  },
};

export const searchModal = {
  defaults: {
    shouldUseHybridSearch: true,
  },
};

export const headerExtraRewards = {
  defaults: {
    viewAllLanguage: 'See all stores',
    getTitle: (earnType) => `Shop these stores offering extra ${earnType}.`,
  },
};

export const navBanner = {
  defaults: {
    bannerText: {
      button: {
        text: () => <>Get the new AAdvantage&reg; eShopping&#8480; button and never miss out on miles.</>,
      },
    },
  },
};

export const navCategoryFeatured = {
  defaults: {
    rebateOptions: {
      hideElevationCurrency: false,
      hideRebatePrefix: true,
    },
  },
};

export const navPopularStores = {
  defaults: {
    secTitle: 'Shop by popular stores',
  },
};

export const navSeasonalFlyout = {
  defaults: {
    getTitleText: (earnType) => `Featured extra ${earnType} stores`,
    getLinkTitle: (extraRewardsPageTitle) => `Shop all stores offering ${extraRewardsPageTitle}`,
  },
};

export const onboardingWelcome = {
  defaults: {
    getWelcomeText: (storeCount) => (
      <>
        Here are a few tips for earning the most miles when you shop
        online at {storeCount} popular stores.
      </>
    ),
    welcomeCTAText: 'Next',
  },
};

export const onboardingFavorites = {
  defaults: {
    favoritesHeading: 'Pick 5 or more favorite stores',
    getFavoritesText: (earnType) => (
      <>We'll email you when they offer extra AAdvantage<sup>&reg;</sup> {earnType}.</>
    ),
  },
};

export const favoritesIcon = {
  defaults: {
    useSvgIcon: false,
  },
};

export const onboardingButton = {
  defaults: {
    viewData: {
      getButtonHeader: () => (
        <>Earn AAdvantage<sup>&reg;</sup> miles with the push of a button</>
      ),
      rewardsType: () => (
        <>AAdvantage<sup>&reg;</sup> miles</>
      ),
      customThirdBulletText: 'Find new stores and get the lowest price',
      usePeriod: true,
    },
  },
};

export const onboardingEmail = {
  defaults: {
    shouldOptInBeCheckedByDefault: true,
  },
};

export const onboardingMobileCongratulation = {
  defaults: {
    image: congratsImage,
    getCongratsText: () => (
      <>You're all set to start shopping and earning AAdvantage<sup>&reg;</sup> miles.</>
    ),
  },
};

export const headerOnboardingCongratulations = {
  defaults: {
    getCongratulationText: () => ('You\'re all set to start shopping and earning miles.'),
    image: congratsImage,
  },
};

export const emailOptInSubscribeModal = {
  defaults: {
    cancelText: 'No thanks, I don\'t like earning extra miles.',
    earnType: 'miles',
    dialogText: 'Sign up to receive emails and be in the know.',
    submitButtonText: 'Sign up for email',
    imageBeforeHeader: true,
    form: 'UpdateMailPreferences',
    formAction: 'member.php',
    emailOptions: {
      email_news_optin: 1,
      email_discovery_optin: 1,
      email_odotw_optin: 1,
      email_solo_optin: 1,
      email_local_optin: 1,
      email_global_optout: 0,
    },
  },
};

export const trendingAndSeasonalOffers = {
  defaults: {
    getTitle: () => (<>More ways to earn AAdvantage&#174; miles</>),
  },
};

export const earningsSnapshot = {
  defaults: {
    getMarketingProgramName: () => (<>AAdvantage&#174;</>),
  },
};

export const exploreCategories = {
  defaults: {
    getTitle: (earnType) => <>Shop these categories and earn AAdvantage&#174; {earnType}</>,
  },
};

export const emailOptInHome = {
  defaults: {
    emailOptionsSubscribe: {
      email_news_optin: 1,
      email_discovery_optin: 1,
      email_odotw_optin: 1,
      email_solo_optin: 1,
      email_local_optin: 1,
      email_global_optout: 0,
    },
    emailOptionsUnsubscribe: {
      email_news_optin: 0,
      email_discovery_optin: 0,
      email_odotw_optin: 0,
      email_solo_optin: 0,
      email_local_optin: 0,
      email_global_optout: 1,
    },
    getMarketingProgramName: () => (<>AAdvantage&#174;</>),
    form: 'UpdateMailPreferences',
    formAction: 'member.php',
  },
};

export const popularOffers = {
  defaults: {
    carousel: {
      isInverted: true,
      breakpoints: {
        m: { isInverted: false },
      },
    },
    rebateOptions: {
      additionalRebatePrefix: 'Plus',
      tiered: {
        additionalRebatePrefix: 'Plus',
      },
    },
    getShouldShowViewAllLink: (isMobileMQ) => (!isMobileMQ && false),
  },
};

export const featuredDeals = {
  defaults: {
    getViewAllLinkText: (isMobileMQ) => (
      isMobileMQ ? 'See all deals' : 'See all'
    ),
  },
};

export const topStores = {
  defaults: {
    getViewAllLinkText: () => (<>See all stores</>),
    viewAllLinkCTAText: 'See all stores',
  },
};

export const merchantLogos = {
  h: {
    apiParams: {
      limit: 8,
    },
    useCarouselOnMediaQueriesOnly: ['M', 'L'],
  },
};

export const howItWorks = {
  defaults: {
    hiwItems: [
      {
        image: hiwExploreImage,
        title: 'Explore',
        description: ({ storeCount }) => (
          <>Explore over {storeCount} stores, exclusive offers and bonus promotions, all in one place.</>
        ),
      },
      {
        image: hiwClickImage,
        title: 'Click',
        description: () => (
          <>Click on any store or offer and you'll be taken to the store's website.</>
        ),
      },
      {
        image: hiwShopImage,
        title: 'Shop',
        description: () => (
          <>Shop like you normally do and pay with any credit card — no special card needed.</>
        ),
      },
      {
        image: hiwEarnImage,
        title: 'Earn',
        description: () => (
          <>
            After making a purchase,
            miles earned will be added to your AAdvantage&#174; account.
          </>
        ),
      },
    ],
    checkShouldShowVideoButton: (userIsIdentified, activeMQ, isPersonalizedMhp) => {
      if (isPersonalizedMhp) return true;
      return !userIsIdentified;
    },
    getSectionTitleText: (userIsIdentified, earnType, isPersonalizedMhp) => {
      if (isPersonalizedMhp) return 'Start earning miles today!';
      return userIsIdentified ? 'How it works' : 'Log in to start earning miles today';
    },
    videoId: 'gzvr8oxqro',
    getVideoAutoplay: () => (false),
  },
};

export const homeButtonBenefits = {
  defaults: {
    buttonHeader: 'Earn miles with the push of a button',
    buttonDescription: 'Add the button browser extension for Chrome and you\'ll '
    + 'get notifications while shopping so you never forget to earn miles. Plus, you can:',
    buttonCopy: [
      'Automatically apply coupons at checkout',
      'Find new stores offering miles per dollar',
      'Get the lowest price with comparison features',
    ],
    buttonImage: homeButtonImage,
  },
};

export const testimonials = {
  defaults: {
    testimonialsCopy: [
      '"Any time I shop online I always check to see if where I\'m shopping is on your participating store list.' +
      ' With your list being so large, it usually is so why not take advantage of this easy way to earn miles."',
      '"The more I buy online, the more miles I get, plus with merchants ' +
      'occasionally increasing the miles given, my miles really increase.' +
      ' I\'m going to Italy this Spring with the miles I\'ve earned. ' +
      'That\'s why I recommend this to family and friends."',
      '"It\'s shopping that I do anyway, and, bonus, I get frequent flyer miles out of it ' +
      '- enough to go round-trip to Europe every year!"',
    ],
    checkShouldShowIconInSlide: (activeMQ, userIsIdentified) =>
      (!userIsIdentified && ['XS', 'S', 'M', 'L', 'XL', 'XXL'].includes(activeMQ)),
    checkShouldUseCarousel: (userIsIdentified) => (!userIsIdentified),
    checkShouldShowIconInCarousel: (activeMQ, userIsIdentified) => (userIsIdentified && ['XS', 'S'].includes(activeMQ)),
    getSectionTitle: (rewardCount, earnType, userIsIdentified) => (
      <>
        Join members earning {rewardCount}{' '}
        {userIsIdentified ? <>AAdvantage&#174;</> : ''} {earnType} on average annually
      </>
    ),
  },
};

export const featuredOffersLogo = {
  defaults: {
    getTitle: (storeCount) => (
      <>Earn at {storeCount}+ stores</>
    ),
    getViewAllLinkUrl: () => '',
  },
};

export const stickyCtaBar = {
  defaults: {
    topOffsetSelector: '.mn_primaryHeroWrap',
    useStickyBehaviorOnMediaQueriesOnly: ['L', 'XL', 'XXL'],
    viewOptions: {
      title: <>Turn your shopping into more AAdvantage&reg; miles today</>,
      buttonLanguage: 'Log in',
    },
    urlTitle: 'Log in',
  },
};

export const bonusOffers = {
  defaults: {
    rebateOptions: {
      additionalRebatePrefix: 'Plus',
      tiered: {
        additionalRebatePrefix: 'Plus',
      },
    },
  },
};

export const infoBar = {
  defaults: {
    infoBarAccordion: {
      couponCodeLanguage: 'Shop with code: ',
    },
    dealOfTheWeek: {
      dateFormat: 'MM/DD/YYYY',
      rebateOptions: {
        hideElevationCurrency: true,
        tiered: {
          hideElevationCurrency: true,
        },
      },
    },
    salesInfoBar: {
      dateFormat: 'MM/DD/YYYY',
      rebateOptions: {
        hideElevationCurrency: true,
        tiered: {
          hideElevationCurrency: true,
        },
      },
    },
  },
};

export const navCategoryFlyout = {
  defaults: {
    shouldShowMerchantName: true,
    rebateOptions: {
      hideNewValuePrefix: true,
    },
  },
};

export const letterboxTOF = {
  defaults: {
    dateFormat: 'MMM. DD, YYYY.',
    getCouponCodeLabelText: () => 'Shop with code: ',
    rebateOptions: {
      showAdditionalElevationPrefix: true,
    },
    shouldShowPlayPauseButton: true,
  },
};

export const firstFavoriteModal = {
  defaults: {
    titleText: 'Success! You\'ve picked a favorite store',
    getBodyText: (siteUrl) => (
      <>
        Keep picking your favorites and we'll email you right away when they offer extra miles.
        To manage your alert preferences, please visit
        {' '}
        <a className="mn_myAccountLink" href={`${siteUrl}/ai____.htm`}>My account</a>.
      </>
    ),
  },
};

export const marketingMerchantsHome = {
  defaults: {
    secTitlePill: 'Extra miles',
  },
};

export const adBlockWarning = {
  defaults: {
    getWarningMessage: () => (
      `The use of ad blockers may prevent you from earning AAdvantage&reg; miles on your shopping.
      While shopping with AAdvantage&reg; eShopping&#8480;, turn off any ad blockers or use a different
      browser to ensure you earn miles as expected.`
    ),
  },
};

export const buttonScrollingModal = {
  defaults: {
    components: {
      ButtonScrollingModalContent,
    },
  },
};

export const merchantOffer = {
  defaults: {
    rebateOptions: {
      hideElevationCurrency: false,
    },
  },
};

export const bonusRollingModal = {
  defaults: {
    isClientEligibleToShowModal: true,
    getClientName: () => <>AAdvantage&reg;</>,
  },
};

export const bonusHomeBanner = {
  defaults: {
    getClientName: () => <>AAdvantage&reg;</>,
  },
};

export const recentStores = {
  defaults: {
    viewData: {
      footerCTATitle: 'View all stores',
    },
    rebateOptions: {
      showAdditionalElevationPrefix: false,
      hideRebatePrefix: true,
      tiered: {
        showAdditionalElevationPrefix: false,
        hideRebatePrefix: true,
      },
    },
  },
};

export const hybridStores = {
  defaults: {
    label: '',
  },
};

export const productSearchResults = {
  defaults: {
    rebateOptions: {
      hideElevationCurrency: true,
    },
  },
};

export const similarStores = {
  defaults: {
    rebateOptions: {
      hideRebatePrefix: true,
    },
  },
};

export const safariButtonInstallTutorial = {
  defaults: {
    clientLogoImage,
    animatedArrow,
    nonSafariIcon,
    getNonSafariHeader: () => <>This American AAdvantage&reg; eShopping&#8480; button is for Safari.</>,
    setPermissionClickImage,
    setPermissionHeader: 'Set permissions',
    setPermissionTextLong: 'Setting the permissions correctly allows the button to check for opportunities '
    + 'to earn miles and use coupons whenever you visit a participating merchant site.',
    setPermissionTextShort: 'Shop with confidence, knowing you\'ll never miss out on earning miles!',
    installDescriptionTextShort: 'Ready to effortlessly save and earn miles while you shop?',
    getInstallDescriptionTextLong: () => (
      <>
        Discover how the AAdvantage&reg; eShopping&#8480; button maximizes your miles-earning
        potential and savings with these tools.
      </>
    ),
    activationReminderImage,
    activationConfirmationVideo,
    couponMagicImage,
    couponSuccessVideo,
    priceMagicVideo,
    searchIntegrationVideo,
    storeOfferSettingsVideo,
  },
};

export const emailOptInSuccessModal = {
  defaults: {
    earnType: 'bonus miles',
  },
};
