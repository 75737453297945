import React from 'react';
import Modal from 'core/modules/Modal/Modal';
import SectionTitle from 'core/modules/SectionTitle/SectionTitle';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import { InstoreLoginLink } from 'core/modules/Instore2/partials';

import './SignInModal.scss';

function SignInModal() {
  return (
    <Modal className="mn_instoreModal mn_signInModal">
      <SectionTitle id="mn_dialogTitle" title="Please sign in to manage your available cards" />
      <InstoreLoginLink className="mn_button">Sign in</InstoreLoginLink>
    </Modal>
  );
}

export default (props) => <ComponentErrorBoundary><SignInModal {...props} /></ComponentErrorBoundary>;
