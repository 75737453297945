import { getDisplayPageName } from 'core/utils/analytics/pageInfo';
import { findAncestorByClassName } from 'core/utils/helpers/dom';
import {
  INSTORE_OFFER_MODAL, INSTORE_EASY_LINK_MODAL, ME_PAGE_INSTORE_ONLY, ME_PAGE_INSTORE,
} from 'core/modules/Instore2/utils/constants';
import {
  INSTORE_ADD_NEW_CARD_MODAL_VIEWED, INSTORE_ADD_NEW_CARD_MODAL_CLOSED,
  INSTORE_OFFER_DETAILS_VIEWED, INSTORE_NEW_CARD_ADDED, INSTORE_NEW_CARD_POST_MESSAGED,
  INSTORE_NEW_CARD_ADD_FAILED,
  INSTORE_LINK_OFFER_FAILED, INSTORE_LINK_OFFER_SUCCEEDED, INSTORE_UNLINK_BUTTON_CLICKED,
  INSTORE_UNLINK_OFFER_SUCCEEDED, INSTORE_UNLINK_OFFER_FAILED, INSTORE_REMOVE_CARD_FAILED,
  INSTORE_EDUCATIONAL_TILE_VIEWED, INSTORE_OFFER_TILES_VIEWED,
} from '../actions/types';

const getCategorySource = (initiator) => {
  switch (initiator) {
    case INSTORE_OFFER_MODAL: { return 'In-store offer overlay'; }
    case ME_PAGE_INSTORE: { return 'Merch Details Page In-store + Online'; }
    case ME_PAGE_INSTORE_ONLY: { return 'Merch Details Page In-store Only'; }
    case INSTORE_EASY_LINK_MODAL: { return 'Easy-Link Modal'; }
    default: { return 'In-store Tile'; }
  }
};

const getErrorLabelSource = (initiator) => {
  switch (initiator) {
    case INSTORE_OFFER_MODAL: { return 'Offer Overlay'; }
    case ME_PAGE_INSTORE: { return 'Online + In-Store Merchant Details Page'; }
    case ME_PAGE_INSTORE_ONLY: { return 'In-Store Only Merchant Details Page'; }
    case INSTORE_EASY_LINK_MODAL: { return 'Easy Link Modal from Online/In-store Merchant Click'; }
    default: { return 'In-store Tile'; }
  }
};

const extractEventData = (event) => {
  const {
    autoLink, easyLink, initiator, offer = {},
  } = event?.context?.options || {};
  const { rclonMerchantName } = offer;
  const { status } = event?.error || {};

  return {
    autoLink,
    easyLink,
    initiator,
    rclonMerchantName,
    status,
  };
};

export const instoreGaActionEvents = {
  [INSTORE_ADD_NEW_CARD_MODAL_VIEWED]: () => ({
    category: 'In-store',
    action: 'Initiated add card process',
    label: 'manage-cards',
  }),
  [INSTORE_ADD_NEW_CARD_MODAL_CLOSED]: () => ({
    category: 'In-store',
    action: 'Closed add card prompt',
  }),
  [INSTORE_NEW_CARD_ADDED]: () => ({
    category: 'In-store',
    action: 'Added card',
  }),
  [INSTORE_NEW_CARD_ADD_FAILED]: ({ event = {}, merchantName = '' }) => {
    const { status } = event.data || {};
    return {
      category: 'In-Store Error',
      action: 'Card Registration Failed Due to RCLON API Error',
      label: `Add Card Flow | ${status} | ${merchantName}`,
    };
  },
  [INSTORE_NEW_CARD_POST_MESSAGED]: ({ event }) => {
    const { origin, data } = event || {};
    const { status } = data || {};
    return {
      category: 'In-store',
      action: 'Post add card window event received',
      label: `${origin}|${status}`,
    };
  },
  [INSTORE_OFFER_DETAILS_VIEWED]: ({ offer, initiator, params = {} }) => {
    const { rclonMerchantName } = offer;
    const getLabel = () => {
      if (initiator === INSTORE_EASY_LINK_MODAL) {
        if (params.noAnyCards) return `No Card - ${rclonMerchantName}`;
        if (params.wasAlreadyLinked) return `Previously Linked - ${rclonMerchantName}`;
        return `Offer Linked - ${rclonMerchantName}`;
      }

      return rclonMerchantName;
    };

    return {
      category: getCategorySource(initiator),
      action: 'View',
      label: getLabel(),
    };
  },
  [INSTORE_UNLINK_BUTTON_CLICKED]: ({ initiator, merchantName }) => ({
    category: getCategorySource(initiator),
    action: 'Unlink step 1',
    label: merchantName,
  }),
  [INSTORE_UNLINK_OFFER_SUCCEEDED]: ({ event }) => {
    const { initiator, rclonMerchantName } = extractEventData(event);
    return {
      category: getCategorySource(initiator),
      action: 'Unlink step 2',
      label: rclonMerchantName,
    };
  },
  [INSTORE_UNLINK_OFFER_FAILED]: ({ event }) => {
    const { initiator, offer = {} } = event?.context?.options || {};
    const { rclonMerchantName } = offer;
    const { status } = event?.error || {};

    return {
      category: 'In-Store Error',
      action: 'Unlink failed',
      label: `${getErrorLabelSource(initiator)} | ${status} | ${rclonMerchantName}`,
    };
  },
  [INSTORE_LINK_OFFER_SUCCEEDED]: ({ event }) => {
    const {
      autoLink, easyLink, initiator, rclonMerchantName,
    } = extractEventData(event);
    return {
      category: getCategorySource(initiator),
      action: (autoLink || easyLink) ? 'Easy-link occurred' : 'Manual link occurred',
      label: rclonMerchantName,
    };
  },
  [INSTORE_LINK_OFFER_FAILED]: ({ event }) => {
    const {
      autoLink, easyLink, initiator, rclonMerchantName, status,
    } = extractEventData(event);
    return {
      category: 'In-Store Error',
      action: (autoLink || easyLink) ? 'Easy-link failed' : 'Manual link failed',
      label: `${getErrorLabelSource(initiator)} | ${status} | ${rclonMerchantName}`,
    };
  },
  [INSTORE_REMOVE_CARD_FAILED]: ({ event }) => {
    const { status } = extractEventData(event);

    return {
      category: 'In-Store Error',
      action: 'Card Removal Failed Due to RCLON API Error',
      label: `Manage Cards Module | ${status}`,
    };
  },
  [INSTORE_EDUCATIONAL_TILE_VIEWED]: ({ isAddCardTile }) => ({
    category: 'MHP in-store contents',
    action: 'View',
    label: isAddCardTile ? 'Add a card educational tile' : 'View offers educational tile',
  }),
  [INSTORE_OFFER_TILES_VIEWED]: ({ offers = [] }) => {
    const merchantNames = offers.map(({ rclonMerchantName }) => rclonMerchantName);

    return {
      category: 'In-store tile',
      action: 'View',
      label: `${merchantNames.join(', ')} | ${getDisplayPageName()}`,
      value: merchantNames.length,
    };
  },
};

export const instoreClickAndHoverEvents = [
  {
    selector: '.mn_instoreSorting.mn_desktop button, .mn_instoreSorting.mn_mobile .mn_dropdownMenu button',
    click: {
      category: 'User adjusts content',
      action: 'Chose from sort dropdown',
    },
    label: (element) => element.textContent,
  },
  {
    selector: '.mn_instoreSettings .mn_dropdownMenu button',
    click: {
      category: 'Settings',
      action: 'Made choice from wheel dropdown',
    },
    label: (element) => element.textContent,
  },
  {
    selector: '.mn_curatedInstoreOffers .mn_viewAllLink',
    click: {
      category: 'MHP in-store content',
      action: 'View all offers click',
    },
  },
  {
    selector: '.mn_instoreOfferTile',
    click: {
      category: 'In-store',
      action: 'Click',
    },
    label: (element) => {
      const { merchantName, gmid } = element.dataset;

      return `${getDisplayPageName()} | logo version | ${gmid}:${merchantName}`;
    },
  },
  {
    selector: '.mn_instoreOfferTile .mn_button',
    click: {
      category: 'In-store tile',
      action: 'Click',
    },
    label: (element) => {
      const tileElement = findAncestorByClassName(element, 'mn_instoreOfferTile') || {};
      const { merchantName } = tileElement.dataset;
      const ctaText = element.textContent;

      return `${ctaText} | ${merchantName} | ${getDisplayPageName()}`;
    },
  },
  {
    selector: '.mn_instoreOfferModal .mn_easyLinkWithoutAnyCardsView .mn_addCardButton',
    click: {
      category: 'Easy-Link Modal',
      action: 'Add a card click',
    },
    label: (element) => {
      const tileElement = findAncestorByClassName(element, 'mn_modal') || {};
      const { merchantName } = tileElement.dataset;
      return `No Card - ${merchantName}`;
    },
  },
  {
    selector: '.mn_instoreEducationalTile.mn_addCardTile',
    click: {
      category: 'MHP in-store contents',
      action: 'Click',
    },
    label: 'Add a card educational tile',
  },
  {
    selector: '.mn_instoreEducationalTile.mn_linkOffersTile',
    click: {
      category: 'MHP in-store contents',
      action: 'Click',
    },
    label: 'View offers educational tile',
  },
  {
    selector: '.mn_meInstoreOffer .mn_toggleButton[aria-expanded="false"]',
    click: {
      category: 'Merch Details Page In-store + Online',
      action: 'Expand',
    },
    label: (element) => {
      const instoreOfferElement = findAncestorByClassName(element, 'mn_meInstoreOffer') || {};
      const { merchantName } = instoreOfferElement.dataset || {};
      return merchantName;
    },
  },
];
