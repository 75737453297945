import React from 'react';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import Modal from 'core/modules/Modal/Modal';
import { useCloseCanJsModal } from 'core/modules/Instore2/hooks/_can/useCloseCanJsModal';
import EasyLinkView from 'core/modules/Instore2/components/InstoreOfferDetails/components/EasyLinkView';

function EasyLinkModal({ offer, wasAlreadyLinked, closeModal }) {
  useCloseCanJsModal();

  return (
    <Modal className="mn_instoreModal mn_instoreOfferModal">
      <EasyLinkView offer={offer} wasAlreadyLinked={wasAlreadyLinked} closeModal={closeModal} />
    </Modal>
  );
}

export default (props) => <ComponentErrorBoundary><EasyLinkModal {...props} /></ComponentErrorBoundary>;
