import React from 'react';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { selectCookieDomain, selectIsHttps } from 'core/selectors/env';
import { selectLoginUrl, selectRegistrationUrl } from 'core/selectors/app';

function LoginLink({
  children, isource, url, title, className, useRegistrationLink, clickCallback,
}) {
  const cookieDomain = useSelector(selectCookieDomain);
  const isHttps = useSelector(selectIsHttps);
  const loginUrl = useSelector(selectLoginUrl);
  const registrationUrl = useSelector(selectRegistrationUrl);

  const urlLink = useRegistrationLink ? registrationUrl : url;

  const handleClick = () => {
    Cookies.set('mn_isource', isource, {
      path: '/',
      domain: cookieDomain,
      secure: isHttps,
      sameSite: 'None',
    });
    clickCallback?.();
  };

  return (
    <a
      className={`mn_loginLink ${className}`}
      onClick={handleClick}
      data-isource={isource}
      href={urlLink || loginUrl}
      title={title}
      data-ga-track-login
    >
      {children}
    </a>
  );
}

LoginLink.defaultProps = {
  isource: 'login link',
  className: '',
  title: 'Sign in',
};

export default LoginLink;
