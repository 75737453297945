import { BONUS_TERMS_VIEW } from '../actions/types';

export const bonusTermsGaActionEvents = {
  [BONUS_TERMS_VIEW]: () => {
    const bannerElement = document.querySelector('.mn_bonusHomeBanner a');
    const dataId = bannerElement?.getAttribute('data-id');

    return {
      category: 'Automatic Public Bonus: VHP Terms Section',
      action: 'View',
      label: dataId,
    };
  },
};
