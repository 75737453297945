import {
  MEMBER_GET_INSTORE_PAYMENT_CARDS,
  MEMBER_GET_INSTORE_PAYMENT_CARDS_FAILURE,
  MEMBER_GET_INSTORE_PAYMENT_CARDS_SUCCESS,
} from 'core/actions/member';
import { INSTORE_FORCE_UPDATE } from 'core/modules/Instore2/actions/types';

const initialState = {
  cards: [],
  isLoading: false,
  isLoaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MEMBER_GET_INSTORE_PAYMENT_CARDS_SUCCESS: {
      const cards = action.payload.response;
      return {
        ...state,
        cards,
        isLoading: false,
        isLoaded: true,
      };
    }
    case MEMBER_GET_INSTORE_PAYMENT_CARDS_FAILURE: {
      return {
        ...state,
        cards: [],
        isLoading: false,
        isLoaded: true,
      };
    }
    case MEMBER_GET_INSTORE_PAYMENT_CARDS: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    }
    case INSTORE_FORCE_UPDATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
