import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useApiFeedbackManager } from 'core/hooks/useApiFeedbackManager';
import { postInstoreCardLink } from 'core/actions/member';
import { clearInstoreLinkFailure } from 'core/modules/Instore2/actions';
import { selectEasyLinkSuppression } from 'core/selectors/user';
import { selectDynamicCardLinksFeedback } from 'core/modules/Instore2/selectors';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import {
  LinkWarning, Disclaimer, ManageCardsButton, LinkOfferButton, Logo, UnlinkSuccessMessage,
} from 'core/modules/Instore2/partials';
import { INSTORE_OFFER_MODAL, ME_PAGE_INSTORE_ONLY } from 'core/modules/Instore2/utils/constants';
import { InstoreOfferModalTitle, InstoreOnlyPill } from '../partials';

import '../InstoreOfferDetails.scss';

function WithoutLinkedCardsView({
  offer, error, isModal,
}) {
  const dispatch = useDispatch();
  const dynamicCardLinksFeedback = useSelector(selectDynamicCardLinksFeedback);
  const easyLinkSuppression = useSelector(selectEasyLinkSuppression);

  const {
    displayRate, currency, rclonMerchantName, disclaimer, offerId,
  } = offer;
  const initiator = isModal ? INSTORE_OFFER_MODAL : ME_PAGE_INSTORE_ONLY;

  const { clearFeedback, feedbackData } = useApiFeedbackManager({
    data: dynamicCardLinksFeedback,
    callbackAction: clearInstoreLinkFailure,
  });

  const { hasLinkFailedFeedback, hasUnlinkSuccessFeedback } = feedbackData;

  useEffect(() => {
    if (!easyLinkSuppression) {
      // autolink offer
      dispatch(postInstoreCardLink(offerId, {}, {}, { autoLink: true, initiator, offer }));
    }
  }, [easyLinkSuppression]);

  return (
    <div className="mn_instoreOfferDetails mn_withoutLinkedCardsView">
      {!isModal && <InstoreOnlyPill />}
      {!isModal && <Logo offer={offer} />}
      <InstoreOfferModalTitle offer={offer} />
      <div id="mn_dialogDescription" className="mn_offerInfo">
        Link this offer to earn {displayRate} {currency} in-store at {rclonMerchantName}
      </div>
      <LinkWarning hasError={error || hasLinkFailedFeedback} />
      <LinkOfferButton offer={offer} onClickCallback={clearFeedback} initiator={initiator} />
      <ManageCardsButton />
      <UnlinkSuccessMessage hasMessage={hasUnlinkSuccessFeedback} />
      <Disclaimer disclaimer={disclaimer} />
    </div>
  );
}

export default (props) => <ComponentErrorBoundary><WithoutLinkedCardsView {...props} /></ComponentErrorBoundary>;
