import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { marketingText as orgConfig } from 'org/configs/modules.config';
import {
  MARKETING_TEXT_GET_SUCCESS,
  MARKETING_TEXT_GET_FAILURE,
} from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    textClockBar: ':',
    api: {
      options: {
        success: MARKETING_TEXT_GET_SUCCESS,
        failure: MARKETING_TEXT_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
