import React from 'react';

import './Disclaimer.scss';

function Disclaimer({ title, disclaimer }) {
  if (!disclaimer) {
    return null;
  }

  return (
    <div className="mn_instoreOfferDisclaimer">
      {title && <div className="mn_title">{title}</div>}
      {disclaimer}
    </div>
  );
}

export default Disclaimer;
