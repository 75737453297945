/*
  This hook triggers view GA event for different instore offer details sections
*/
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { usePrevious } from 'core/hooks/usePrevious';
import { triggerInstoreOfferDetailsIsViewedGaEvent } from 'core/modules/Instore2/actions';

export const useTriggerInstoreDetailsIsViewedGaEvent = (offer, initiator, params) => {
  const dispatch = useDispatch();
  const prevOffer = usePrevious(offer);

  useEffect(() => {
    if (isEmpty(prevOffer) && !isEmpty(offer)) {
      dispatch(triggerInstoreOfferDetailsIsViewedGaEvent(offer, initiator, params));
    }
  }, [offer]);
};
