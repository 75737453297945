import React from 'react';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import Modal from 'core/modules/Modal/Modal';
import { useCloseCanJsModal } from 'core/modules/Instore2/hooks/_can/useCloseCanJsModal';
import EasyLinkWithoutAnyCardView
  from 'core/modules/Instore2/components/InstoreOfferDetails/components/EasyLinkWithoutAnyCardView';

function EasyLinkWithoutAnyCardsModal({ offer, attributes }) {
  useCloseCanJsModal();

  return (
    <Modal className="mn_instoreModal mn_instoreOfferModal" attributes={attributes}>
      <EasyLinkWithoutAnyCardView offer={offer} />
    </Modal>
  );
}

export default (props) => <ComponentErrorBoundary><EasyLinkWithoutAnyCardsModal {...props} /></ComponentErrorBoundary>;
