import React from 'react';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import SectionTitle from 'core/modules/SectionTitle/SectionTitle';
import orgConfig from 'org/configs/org.config';

function InstoreOfferModalTitle({ offer, shouldShowRate }) {
  const { earnType } = orgConfig;
  const { rclonMerchantName, displayRate, currency } = offer;

  if (shouldShowRate) {
    return (
      <SectionTitle
        id="mn_dialogTitle"
        title={
          <>
            Earn {displayRate} {currency} when you shop
            {' '}
            <span className="mn_noWrap">in-store</span> at {rclonMerchantName}.
          </>
        }
      />
    );
  }

  return (
    <SectionTitle
      id="mn_dialogTitle"
      title={<>Earn {earnType} when you shop <span className="mn_noWrap">in-store</span> at {rclonMerchantName}.</>}
    />
  );
}

export default (props) => <ComponentErrorBoundary><InstoreOfferModalTitle {...props} /></ComponentErrorBoundary>;
