import React from 'react';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import './Warning.scss';

function Warning({ hasError, shouldShowIcon = true, children }) {
  if (!hasError) {
    return null;
  }

  return (
    <div className="mn_instoreWarning">
      {shouldShowIcon && <div className="mn_icon" />}
      <div className="mn_text">{children}</div>
    </div>
  );
}

export default (props) => <ComponentErrorBoundary><Warning {...props} /></ComponentErrorBoundary>;
