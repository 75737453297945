import {
  MEMBER_REGISTER_TOKEN,
  MEMBER_REGISTER_TOKEN_FAILURE,
  MEMBER_REGISTER_TOKEN_SUCCESS,
} from 'core/actions/member';

const initialState = {
  token: '',
  cardHolderId: '',
  isLoading: false,
  isLoaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MEMBER_REGISTER_TOKEN_SUCCESS: {
      const { token, cardHolderId } = action.payload.response;
      return {
        ...state,
        token,
        cardHolderId,
        isLoading: false,
        isLoaded: true,
      };
    }
    case MEMBER_REGISTER_TOKEN_FAILURE: {
      return {
        ...state,
        tokenFailureMessage: action.payload.name,
        isLoading: false,
        isLoaded: true,
      };
    }
    case MEMBER_REGISTER_TOKEN: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    }
    default:
      return state;
  }
};
