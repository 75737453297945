import {
  INSTORE_FORCE_UPDATE, INSTORE_OFFERS_GET_SUCCESS, INSTORE_OFFERS_GET_FAILURE,
} from 'core/modules/Instore2/actions/types';

const initialState = {
  defaults: {
    offers: [],
    isLoading: false,
    isLoaded: false,
  },
};

export default (state = initialState, action) => {
  const namespaceId = action?.options?.namespace || action?.context?.options?.namespace || 'default';
  const namespace = `namespace-${namespaceId}`;
  const { defaults } = state;
  const { [namespace]: namespaceState = defaults } = state;

  switch (action.type) {
    case INSTORE_OFFERS_GET_SUCCESS: {
      const offers = action.payload.response;

      return {
        ...state,
        [namespace]: {
          ...namespaceState,
          offers,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case INSTORE_OFFERS_GET_FAILURE: {
      return {
        ...state,
        [namespace]: {
          ...namespaceState,
          offers: [],
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case INSTORE_FORCE_UPDATE: {
      const updatedState = Object.fromEntries(
        Object.entries(state).map(([key, value]) => [key, {
          ...value,
          isLoading: false,
          isLoaded: false,
        }]),
      );
      return updatedState;
    }
    default: {
      const { options = {} } = action;
      if (options.success === INSTORE_OFFERS_GET_SUCCESS) {
        return {
          ...state,
          [namespace]: {
            ...namespaceState,
            isLoading: true,
          },
        };
      }

      return state;
    }
  }
};
