import { APP_UPDATE, UPDATE_EASY_LINK_SUPPRESION_PROPERTY } from './types';

export const updateApp = payload => ({
  type: APP_UPDATE,
  payload,
});

export const updateEasyLinkSuppresionProperty = payload => ({
  type: UPDATE_EASY_LINK_SUPPRESION_PROPERTY,
  payload,
});
