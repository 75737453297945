import React from 'react';
import Modal from 'core/modules/Modal/Modal';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import WithoutLinkedCardsView
  from 'core/modules/Instore2/components/InstoreOfferDetails/components/WithoutLinkedCardsView';

function WithoutLinkedCardsModal({ offer, error, attributes }) {
  return (
    <Modal
      className="mn_instoreModal mn_instoreOfferModal"
      options={{ shouldUpdateUrl: true }}
      attributes={attributes}
    >
      <WithoutLinkedCardsView offer={offer} error={error} isModal />
    </Modal>
  );
}

export default (props) => <ComponentErrorBoundary><WithoutLinkedCardsModal {...props} /></ComponentErrorBoundary>;
