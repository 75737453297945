import { SUCCESS_SUFFIX, FAILURE_SUFFIX } from 'core/actions/apis';

const rootActionPath = 'core/Instore2';

export const INSTORE_FORCE_UPDATE = `${rootActionPath}/FORCE_UPDATE`;

export const INSTORE_OFFERS_GET_SUCCESS = `${rootActionPath}Offers/GET_${SUCCESS_SUFFIX}`;
export const INSTORE_OFFERS_GET_FAILURE = `${rootActionPath}Offers/GET_${FAILURE_SUFFIX}`;

export const INSTORE_OFFERS_SECTION_TITLE_GET_SUCCESS = `${rootActionPath}OffersSectionTitle/GET_${SUCCESS_SUFFIX}`;
export const INSTORE_OFFERS_SECTION_TITLE_GET_FAILURE = `${rootActionPath}OffersSectionTitle/GET_${FAILURE_SUFFIX}`;

export const INSTORE_LOCATION_OFFERS_GET_SUCCESS = `${rootActionPath}Locations/GET_${SUCCESS_SUFFIX}`;
export const INSTORE_LOCATION_OFFERS_GET_FAILURE = `${rootActionPath}Locations/GET_${FAILURE_SUFFIX}`;

export const INSTORE_CARD_LINK_CLEAR_FAILS = `${rootActionPath}CardLink/CLEAR_FAILS`;
export const INSTORE_CARD_LINK_CLEAR_EASYLINK_OFFER_ID = `${rootActionPath}CardLink/CLEAR_EASYLINK_OFFER_ID`;

export const INSTORE_CARD_ADDITION_COMPLETED = `${rootActionPath}CardAddition/COMPLETED`;

export const INSTORE_PAYMENT_CARD_CLEAR_FAILS = `${rootActionPath}CardDelete/CLEAR_FAILS`;

/* analytics */
export const INSTORE_ADD_NEW_CARD_MODAL_VIEWED = `${rootActionPath}AddNewCardModal/VIEWED`;
export const INSTORE_ADD_NEW_CARD_MODAL_CLOSED = `${rootActionPath}AddNewCardModal/CLOSED`;

export const INSTORE_NEW_CARD_ADDED = `${rootActionPath}NewCard/ADDED`;
export const INSTORE_NEW_CARD_ADD_FAILED = `${rootActionPath}NewCard/ADD_FAILED`;
export const INSTORE_NEW_CARD_POST_MESSAGED = `${rootActionPath}NewCardPost/MESSAGED`;

export const INSTORE_REMOVE_CARD_FAILED = `${rootActionPath}RemoveCard/FAILED`;

export const INSTORE_LINK_OFFER_SUCCEEDED = `${rootActionPath}OfferLink/SUCCEEDED`;
export const INSTORE_LINK_OFFER_FAILED = `${rootActionPath}OfferLink/FAILED`;

export const INSTORE_UNLINK_OFFER_SUCCEEDED = `${rootActionPath}OfferUnlink/SUCCEEDED`;
export const INSTORE_UNLINK_OFFER_FAILED = `${rootActionPath}OfferUnlink/FAILED`;

export const INSTORE_UNLINK_BUTTON_CLICKED = `${rootActionPath}UnlinkButton/CLICKED`;

export const INSTORE_OFFER_DETAILS_VIEWED = `${rootActionPath}OfferModal/VIEWED`;
export const INSTORE_EDUCATIONAL_TILE_VIEWED = `${rootActionPath}EducationalTile/VIEWED`;
export const INSTORE_OFFER_TILES_VIEWED = `${rootActionPath}Tiles/VIEWED`;
