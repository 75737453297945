/*
  This hook is used to handle the easy-linking of instore offers when the user
  clicks on any offer associated to the merchant that has in-store offers.
  The logic is as follows:
  1. If the user is identified and is not in an easy-link suppression list listen for clicks on the
     offers associated to the merchants that has in-store offers.
  2. On click on such an offer:
    a. if there are no any cards show the proper "Add a card..." easylink offer modal
    b. if in-store offer already has linked card(s) show the proper "Already linked..." easylink offer modal
    c. If the offer has not been linked, but there are eligible card(s)
       - link the offer in hidden mode to eligible card(s)
       - show the the proper "We've linked..." easylink offer modal but only if the linking was successful
*/

import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ALL_INSTORE_OFFERS_ID, INSTORE_EASY_LINK_MODAL } from 'core/modules/Instore2/utils/constants';
import { useGetAllInstoreOffers } from 'core/modules/Instore2/hooks/useGetInstoreOffers';
import { useModal } from 'core/modules/Modal/ModalProvider';
import { postInstoreCardLink } from 'core/actions/member';
import { clearInstoreLinkEasyLinkedOfferId } from 'core/modules/Instore2/actions';
import { selectDynamicCardEasyLinkedOfferId } from 'core/modules/Instore2/selectors';
import { useApiFeedbackManager } from 'core/hooks/useApiFeedbackManager';
import { selectUserIsIdentified, selectEasyLinkSuppression } from 'core/selectors/user';
import { selectInstoreIsEnabled, selectOrgId } from 'core/selectors/app';

const initiator = INSTORE_EASY_LINK_MODAL;

export const useHandleInstorEasyLinkModal = () => {
  const dispatch = useDispatch();
  const isUserIdentified = useSelector(selectUserIsIdentified);
  const isInstoreEnabled = useSelector(selectInstoreIsEnabled);
  const easyLinkSuppression = useSelector(selectEasyLinkSuppression);
  const orgId = useSelector(selectOrgId);
  const { openModal } = useModal();

  // During United migration to Instore v2.0 `orgId === 76` check should be removed
  const isInstoreV2 = orgId !== 76;
  const ignoreFetching = !isUserIdentified || easyLinkSuppression || !isInstoreEnabled || !isInstoreV2;

  const { offers } = useGetAllInstoreOffers({ ignoreFetching });

  const getOffersByGmid = (offers) => (
    offers.reduce((obj, item) => {
      obj[item.carteraMerchantId] = item;
      return obj;
    }, {})
  );

  const offersByGmid = useMemo(() => getOffersByGmid(offers), [offers]);

  const findInstoreOfferByClickLinkGmid = (event, offersByGmid) => {
    try {
      const link = event.target.closest('a');

      // Ignore clicks on click.php links on the merchant details page
      if (link?.closest('#mn_me .mn_merchantDetails')) {
        return null;
      }

      // Ignore clicks on links that trigger an overlay instead of conducting a redirect
      if (link?.getAttribute('data-lightbox-type')) {
        return null;
      }

      // Ignore clicks on the expandable mobile Letterbox slide unless they are directly on the button
      if (link?.getAttribute('data-expandable-letterbox-slide') && !event.target.closest('.mn_shopNowButton')) {
        return null;
      }

      const { href = '' } = link || {};

      if (href.includes('click.php')) {
        const url = new URL(href.toLowerCase());
        const params = new URLSearchParams(url.search);
        const gmid = params.get('gmid');

        return gmid && offersByGmid[gmid];
      }

      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  useEffect(() => {
    const handleClick = event => {
      const offer = findInstoreOfferByClickLinkGmid(event, offersByGmid);
      if (!offer) {
        return true;
      }

      const {
        offerId, hasEligibleCards, hasLinkedCards, eligibleCards,
      } = offer;
      const hasAnyCards = hasEligibleCards || hasLinkedCards;

      // 2a
      if (!hasAnyCards) {
        setTimeout(() => {
          openModal({
            name: 'mt_instoreOffer2',
            params: {
              offerId,
              namespace: ALL_INSTORE_OFFERS_ID,
              easyLink: true,
              noAnyCards: true,
            },
          });
        }, 100);
        return true;
      }

      // 2b
      if (hasLinkedCards) {
        setTimeout(() => {
          openModal({
            name: 'mt_instoreOffer2',
            params: {
              offerId,
              namespace: ALL_INSTORE_OFFERS_ID,
              easyLink: true,
              wasAlreadyLinked: true,
            },
          });
        }, 100);
        // additionally autolink unlinked cards if available
        eligibleCards.forEach(({ cardId }) => {
          dispatch(postInstoreCardLink(
            offerId, { cardId }, {}, { autoLink: true, initiator, offer },
          ));
        });
        return true;
      }

      // 2c (I)
      const options = {
        namespace: ALL_INSTORE_OFFERS_ID, easyLink: true, initiator, offer,
      };
      dispatch(postInstoreCardLink(offerId, {}, {}, options));
      return true;
    };

    // 1
    if (isUserIdentified && isInstoreEnabled && !easyLinkSuppression && isInstoreV2) {
      document.addEventListener('click', handleClick);
    }

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [isUserIdentified, isInstoreEnabled, offersByGmid, easyLinkSuppression]);

  const dynamicCardEasyLinkedOfferId = useSelector(selectDynamicCardEasyLinkedOfferId);
  const { shouldShowFeedback, clearFeedback, feedbackData } = useApiFeedbackManager({
    data: dynamicCardEasyLinkedOfferId,
    callbackAction: clearInstoreLinkEasyLinkedOfferId,
  });

  // 2c (II)
  useEffect(() => {
    if (shouldShowFeedback) {
      openModal({
        name: 'mt_instoreOffer2',
        params: {
          offerId: feedbackData,
          namespace: ALL_INSTORE_OFFERS_ID,
          easyLink: true,
          wasAlreadyLinked: false,
        },
      });
      clearFeedback();
    }
  }, [shouldShowFeedback, clearFeedback, feedbackData]);
};
