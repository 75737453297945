import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { instore2 as orgConfig } from 'org/configs/modules.config';
import {
  INSTORE_OFFERS_GET_SUCCESS, INSTORE_OFFERS_GET_FAILURE,
  INSTORE_OFFERS_SECTION_TITLE_GET_SUCCESS, INSTORE_OFFERS_SECTION_TITLE_GET_FAILURE,
  INSTORE_LOCATION_OFFERS_GET_SUCCESS, INSTORE_LOCATION_OFFERS_GET_FAILURE,
} from '../actions/types';

const defaultConfig = {
  defaults: {
    instoreOffers: {
      api: {
        title: {
          params: {},
          options: {
            success: INSTORE_OFFERS_SECTION_TITLE_GET_SUCCESS,
            failure: INSTORE_OFFERS_SECTION_TITLE_GET_FAILURE,
            cache: { isEnabled: true, isPublishedData: true },
          },
        },
        content: {
          params: {},
          options: {
            success: INSTORE_OFFERS_GET_SUCCESS,
            failure: INSTORE_OFFERS_GET_FAILURE,
            cache: { isEnabled: false, isPublishedData: false },
          },
        },
      },
    },
    instoreLocations: {
      api: {
        params: {
          radius: 60,
          fields: ['carteraMerchantId', 'carteraMerchantName', 'distance'],
        },
        options: {
          success: INSTORE_LOCATION_OFFERS_GET_SUCCESS,
          failure: INSTORE_LOCATION_OFFERS_GET_FAILURE,
          cache: { isEnabled: true, isPublishedData: true },
        },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
