import React from 'react';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import { useModal } from 'core/modules/Modal/ModalProvider';

function ManageCardsButton({ className = 'mn_basicButton', onClickCallback }) {
  const { openModal } = useModal();

  const openManageCardsModal = () => {
    openModal({
      name: 'mt_instoreManageCards2',
    });

    onClickCallback?.();
  };

  return (
    <button className={className} onClick={openManageCardsModal}>Manage cards</button>
  );
}

export default (props) => <ComponentErrorBoundary><ManageCardsButton {...props} /></ComponentErrorBoundary>;
