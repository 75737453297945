import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UrlParse from 'url-parse';
import {
  triggerOnCardAddedEffects, triggerNewCardIsAddedGaEvent,
  triggerNewCardPostMessageGaEvent, triggerNewCardAddIsFailedGaEvent,
} from 'core/modules/Instore2/actions';
import { postInstoreRegisterToken } from 'core/actions/member';
import { selectInstoreIframeRclonLink } from 'core/selectors/env';
import { selectToken, selectTokenIsLoaded } from 'core/modules/Instore2/selectors';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import { Warning } from 'core/modules/Instore2/partials';
import { useHandleInstoreCookie } from 'core/modules/Instore2/hooks/useHandleInstoreCookie';

import config from '../configs';

function AddNewCardFrame({ offerId, merchantName, openModal }) {
  const dispatch = useDispatch();
  const rclonIframeLink = useSelector(selectInstoreIframeRclonLink);
  const token = useSelector(selectToken);
  const isTokenLoaded = useSelector(selectTokenIsLoaded);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    dispatch(postInstoreRegisterToken());
  }, []);

  useHandleInstoreCookie();

  const onMessageCallback = (event) => {
    const { origin: originIframeURLOrigin } = event;
    const { origin: rclonIframeLinkOrigin } = new UrlParse(rclonIframeLink, true);

    if (originIframeURLOrigin !== rclonIframeLinkOrigin) {
      return;
    }
    dispatch(triggerNewCardPostMessageGaEvent(event));

    if (event?.data?.status === 200) {
      const { cardId } = event.data;
      // Handle side effects in saga after card is added
      dispatch(triggerOnCardAddedEffects({ cardId, offerId }));
      dispatch(triggerNewCardIsAddedGaEvent());

      openModal({ name: 'mt_instoreManageCards2' });
    } else {
      dispatch(triggerNewCardAddIsFailedGaEvent(event, merchantName));
      setHasError(true);
    }
  };

  useEffect(() => {
    window.addEventListener('message', onMessageCallback);
    return () =>
      window.removeEventListener('message', onMessageCallback);
  }, []);

  const getIframeUrl = () => {
    const theme = config.iframeTheme;
    const action = offerId ? 'LINK' : 'ADD';

    return `${rclonIframeLink.trim()}${token.trim()}&theme=${theme}&action=${action}`;
  };

  const iframeUrl = getIframeUrl(token);

  if (hasError) {
    return <Warning hasError>An unknown error has occurred. Please try again.</Warning>;
  }

  if (isTokenLoaded) {
    return <iframe title="Add Card Iframe" id="in-store-pc" className="mn_instoreIframe" src={iframeUrl} />;
  }

  return null;
}

export default (props) => <ComponentErrorBoundary><AddNewCardFrame {...props} /></ComponentErrorBoundary>;
