import React from 'react';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import { cardTypeNames } from 'core/modules/Instore2/utils/cardTypes';
import { LinkOfferButton } from 'core/modules/Instore2/partials';

function Card({
  card, offer, clearWarning, className = '', initiator,
}) {
  const {
    cardId, cardLastFour, isLinked, cardType,
  } = card;

  if (isLinked) {
    return (
      <li className={`mn_instoreCard mn_linkedCard mn_noWrap ${className}`}>
        <span>Linked to {cardTypeNames[cardType]} ****{cardLastFour}</span>
      </li>
    );
  }

  return (
    <li className={`mn_instoreCard mn_notLinkedCard mn_noWrap ${className}`}>
      <span className="mn_instoreCardName">{cardTypeNames[cardType]} ****{cardLastFour}</span>
      <LinkOfferButton
        className="mn_basicButton"
        offer={offer}
        cardId={cardId}
        onClickCallback={clearWarning}
        initiator={initiator}
      />
    </li>
  );
}

export default (props) => <ComponentErrorBoundary><Card {...props} /></ComponentErrorBoundary>;
