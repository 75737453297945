// ids
export const FAQ_ID = 'FAQ';

// namespaces
export const ALL_INSTORE_OFFERS_ID = 'all';
export const FEATURED_INSTORE_OFFERS_ID = 'featured';

// initiators
export const INSTORE_OFFER_MODAL = 'instoreOfferModal';
export const INSTORE_EASY_LINK_MODAL = 'instoreEasyLinkModal';
export const ME_PAGE_INSTORE_ONLY = 'mePageInstoreOnly';
export const ME_PAGE_INSTORE = 'mePageInstore';
