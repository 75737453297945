import bowser from 'bowser';
import { detectIncognito } from 'detectincognitojs';
import SessionStorageCache from 'core/utils/cache/sessionStorage';

const sessionStorageCache = new SessionStorageCache();
const browser = bowser.getParser(window.navigator.userAgent);

export const browserName = browser.parsedResult.browser.name;
export const platformType = browser.parsedResult.platform.type;

export const isMobile = browser.isPlatform('mobile');
export const isTablet = browser.isPlatform('tablet');
export const isDesktop = browser.isPlatform('desktop');
export const isMobileOrTablet = isMobile || isTablet;
export const isTabletOrDesktop = isTablet || isDesktop;

export const isChrome = browser.isBrowser('Chrome');
export const isSafari = browser.isBrowser('Safari');
export const isIE = browser.isBrowser('Internet Explorer');
export const isFirefox = browser.isBrowser('Firefox');
export const isEdge = browser.isBrowser('Microsoft Edge');
export const isEdgeChromium = !!browser.satisfies({ edge: '>79' });
export const isIOS = browser.isOS('iOS');

export const detectIncognitoMode = async () => {
  // First search in the cache, and if not found, then detect asynchronously
  try {
    const IS_INCOGNITO_KEY = 'isIncognito';
    const isIncognitoCached = sessionStorageCache.getItem(IS_INCOGNITO_KEY);

    if (typeof isIncognitoCached === 'boolean') {
      return isIncognitoCached;
    }

    const { isPrivate } = await detectIncognito();
    sessionStorageCache.setItem(IS_INCOGNITO_KEY, isPrivate);
    return isPrivate;
  } catch (e) {
    console.error(e);
    return null;
  }
};
