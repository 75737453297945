import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { navCategoriesTOF as orgConfig } from 'org/configs/modules.config';

const defaultConfig = {
  defaults: {
    secTitle: 'Shop by category',
    allStoresLinkText: 'View all stores',
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
