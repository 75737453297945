import React from 'react';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import Modal from 'core/modules/Modal/Modal';
import WithLinkedCardsView from 'core/modules/Instore2/components/InstoreOfferDetails/components/WithLinkedCardsView';

function WithLinkedCardsModal({ offer, attributes }) {
  return (
    <Modal
      className="mn_instoreModal mn_instoreOfferModal"
      options={{ shouldUpdateUrl: true }}
      attributes={attributes}
    >
      <WithLinkedCardsView offer={offer} isModal />
    </Modal>
  );
}

export default (props) => <ComponentErrorBoundary><WithLinkedCardsModal {...props} /></ComponentErrorBoundary>;
