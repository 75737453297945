import React from 'react';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import { useModal } from 'core/modules/Modal/ModalProvider';

function AddCardButton({
  className = 'mn_button mn_addCardButton', offerId, merchantName,
  onClickCallback, children,
}) {
  const { openModal } = useModal();

  const openAddCardModal = () => {
    openModal({
      name: 'mt_instoreAddNewCard2',
      params: {
        offerId,
        merchantName,
      },
    });

    onClickCallback?.();
  };

  return <button className={className} onClick={openAddCardModal}>{children || 'Add a card'}</button>;
}

export default (props) => <ComponentErrorBoundary><AddCardButton {...props} /></ComponentErrorBoundary>;
