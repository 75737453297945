/*
This saga manages the card addition and linking workflows.
Once a card is added, the `handlePostCardAdditionActions` is triggered to manage the following logic:
 1. Fetch the in-store offers data again, which will now include the information of the newly added card.
 2. Handle the auto-linking process for the newly added card, whether linking it to a selected offer
    or offers that have other linked cards, depending on the user's workflow
*/

import { select, takeEvery, put } from 'redux-saga/effects';
import { postInstoreCardLink, postInstoreMultiOfferCardLink } from 'core/actions/member';
import { INSTORE_CARD_ADDITION_COMPLETED } from 'core/modules/Instore2/actions/types';
import { triggerInstoreForceUpdate } from 'core/modules/Instore2/actions';
import { ALL_INSTORE_OFFERS_ID } from 'core/modules/Instore2/utils/constants';
import { makeSelectDynamicInstoreOffers } from 'core/modules/Instore2/selectors';
import { selectEasyLinkSuppression } from 'core/selectors/user';

function* handlePostCardAdditionActions(action = {}) {
  const { data } = action;
  const { cardId, offerId } = data;

  const selectDynamicInstoreOffers = makeSelectDynamicInstoreOffers();
  const offers = yield select(selectDynamicInstoreOffers, ALL_INSTORE_OFFERS_ID);
  const easyLinkSuppression = yield select(selectEasyLinkSuppression);
  const hasAnyOffersWithLinkedCards = offers.some(offer => offer.hasLinkedCards);

  // re-fetch instore offers with updated cards list
  yield put(triggerInstoreForceUpdate());

  if (!easyLinkSuppression && hasAnyOffersWithLinkedCards) {
    // Link the newly added card to offers that already have other linked cards
    yield put(postInstoreMultiOfferCardLink({ cardId }, {}, { forceUpdate: true }));
    return;
  }

  if (offerId) {
    // Link the newly added card to the specific offer
    yield put(postInstoreCardLink(offerId, { cardId }, {}, { forceUpdate: true }));
  }
}

export default function* onAddCardSaga() {
  yield takeEvery(INSTORE_CARD_ADDITION_COMPLETED, handlePostCardAdditionActions);
}
