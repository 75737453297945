import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectSiteUrl, selectPageName } from 'core/selectors/app';
import { selectUserIsAuthenticated } from 'core/selectors/user';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import Modal from 'core/modules/Modal/Modal';
import SectionTitle from 'core/modules/SectionTitle/SectionTitle';
import SignInModal from 'core/modules/Instore2/components/Modals/SignInModal/SignInModal';
import AddNewCardFrame from './components/AddNewCardFrame';
import { FAQ_ID } from 'core/modules/Instore2/utils/constants';
import {
  triggerAddNewCardModalIsClosedGaEvent, triggerAddNewCardModalIsViewedGaEvent,
} from 'core/modules/Instore2/actions';

import './AddNewCardModal.scss';

function AddNewCardModal({ modal, openModal, closeModal }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isUserAuthenticated = useSelector(selectUserIsAuthenticated);
  const siteUrl = useSelector(selectSiteUrl);
  const pageName = useSelector(selectPageName);

  const { params = {} } = modal;
  const { merchantName, offerId } = params;

  useEffect(() => {
    dispatch(triggerAddNewCardModalIsViewedGaEvent());
  }, []);

  const onCloseCallback = () => {
    dispatch(triggerAddNewCardModalIsClosedGaEvent());
  };

  const onLearnMoreClick = () => {
    const URL = `/instore#${FAQ_ID}`;

    const updateHashToScroll = () => {
      closeModal(onCloseCallback);
      setTimeout(() => { navigate(URL); }, 100);
    };

    pageName === 'is' ? updateHashToScroll() : window.location.assign(URL);
  };

  if (!isUserAuthenticated) {
    return <SignInModal />;
  }

  return (
    <Modal
      className="mn_instoreModal mn_addNewCardModal"
      options={{ shouldUpdateUrl: true }}
      onCloseCallback={onCloseCallback}
    >
      <SectionTitle
        id="mn_dialogTitle"
        title={merchantName ? `Add a card to link to ${merchantName}.` : 'Add a card.'}
      />
      <div className="mn_info" id="mn_dialogDescription">
        We use your linked card to track your qualifying purchases. We'll never charge
        your card. <button className="mn_basicButton" onClick={onLearnMoreClick}>Learn more</button>.
      </div>
      <div className="mn_instoreEnrollCardIframe">
        <AddNewCardFrame offerId={offerId} merchantName={merchantName} openModal={openModal} />
      </div>
      <div className="mn_disclaimer">
        For debit cards, remember to run them as a credit card at checkout (do <strong>not</strong> use your PIN).
        <br />
        See <a href={`${siteUrl}/terms____.htm#instore`}>Terms and Conditions</a> for full card
        and transaction eligibility details.
      </div>
    </Modal>
  );
}

export default (props) => <ComponentErrorBoundary><AddNewCardModal {...props} /></ComponentErrorBoundary>;
