import React from 'react';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import SectionTitle from 'core/modules/SectionTitle/SectionTitle';

function EasylinkModalTitle({ offer, wasAlreadyLinked }) {
  const { displayRate, currency, rclonMerchantName } = offer;

  if (wasAlreadyLinked) {
    return (
      <SectionTitle
        id="mn_dialogTitle"
        title={
          <>
            Good news!
            <br />
            You're ready to earn {displayRate} {currency}
            {' '}
            <span className="mn_noWrap">in-store</span> at {rclonMerchantName}.
          </>
        }
      />
    );
  }

  const formattedMerchantName = rclonMerchantName.endsWith('s') ? `${rclonMerchantName}'` : `${rclonMerchantName}'s`;

  return (
    <SectionTitle
      id="mn_dialogTitle"
      title={
        <>
          Good news!
          <br />
          We've linked your participating card(s)
          to {formattedMerchantName} <span className="mn_noWrap">in-store</span> offer.
        </>
      }
    />
  );
}

export default (props) => <ComponentErrorBoundary><EasylinkModalTitle {...props} /></ComponentErrorBoundary>;
