import React from 'react';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import Warning from 'core/modules/Instore2/partials/Warning/Warning';

export function RemoveCardWarning({ hasError, feedbackData }) {
  return (
    <Warning hasError={hasError}>
      There was a problem deleting the card ending {feedbackData.cardLastFour}. Please try again.
    </Warning>
  );
}

export default (props) => <ComponentErrorBoundary><RemoveCardWarning {...props} /></ComponentErrorBoundary>;
