import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectWidthMQ } from 'core/modules/MediaQueries/selectors';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import config from './configs';

import './Loader.scss';

function Loader({ isLoading, className = '', children }) {
  const widthMQ = useSelector(selectWidthMQ);
  const { loaderImage } = config;
  const childrenRef = useRef(null);
  const [styles, setStyles] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      const firstChild = childrenRef?.current?.firstChild;

      if (firstChild) {
        const rect = firstChild.getBoundingClientRect();
        const computedStyle = window.getComputedStyle(firstChild);

        setStyles({
          width: rect.width,
          height: rect.height,
          margin: computedStyle.margin,
        });
      }
    }, 100);

    return () => clearTimeout(timer);
  }, [children, widthMQ]);

  if (isLoading) {
    return (
      <div className={`mn_loaderWrap ${className}`} style={styles}>
        <img className="mn_loader" src={loaderImage} alt="loading" />
      </div>
    );
  }

  if (className) {
    return <div className={className} ref={childrenRef}>{children}</div>;
  }

  return <div className="mn_loaderContent" ref={childrenRef}>{children}</div>;
}

export default (props) => <ComponentErrorBoundary><Loader {...props} /></ComponentErrorBoundary>;
