import React from 'react';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import { InstoreLoginLink, Logo, Disclaimer } from 'core/modules/Instore2/partials';
import { InstoreOfferModalTitle, InstoreOnlyPill } from '../partials';

import '../InstoreOfferDetails.scss';

function AnonymousView({ offer, isModal }) {
  const {
    displayRate, currency, rclonMerchantName, disclaimer,
  } = offer;

  return (
    <div className="mn_instoreOfferDetails mn_anonymousView">
      {!isModal && <InstoreOnlyPill />}
      {!isModal && <Logo offer={offer} />}
      <InstoreOfferModalTitle offer={offer} />
      <div id="mn_dialogDescription" className="mn_offerInfo">
        Earn {displayRate} {currency} in-store at {rclonMerchantName}
      </div>
      <InstoreLoginLink className="mn_button">Sign in to get the offer</InstoreLoginLink>
      <Disclaimer disclaimer={disclaimer} />
    </div>
  );
}

export default (props) => <ComponentErrorBoundary><AnonymousView {...props} /></ComponentErrorBoundary>;
