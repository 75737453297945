import React, { useEffect, useRef } from 'react';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import { useModal } from 'core/modules/Modal/ModalProvider';
import { useOnClickOutside } from 'core/hooks/useOnClickOutside';
import {
  useOnEscapeKeydown, useTrapFocus, useHandleHiddenAttribute, useHandleUrlUpdates,
} from './hooks';

import config from './configs';

import './Modal.scss';

function Modal({
  className = '', options = {}, onCloseCallback, children, testId = '',
  onClickOutsideCallback, onEscapeKeydownCallback, onClickCloseButtonCallback,
  attributes = {},
}) {
  const { modal, closeModal } = useModal();
  const modalRef = useRef();

  const combinedOptions = {
    ...config.options, // 1. Base (default) config for the modal
    ...options, // 2. Current Modal config
    ...modal.options, // 3. Config from the component that triggers modal
  };

  const {
    shouldShowCloseButton, shouldCloseOnClickOutside, shouldCloseOnEscapeKeydown,
    shouldUpdateUrl, wasTriggeredFromUrl,
  } = combinedOptions;

  const onCloseModal = (customCallback) => {
    customCallback?.();
    onCloseCallback?.();
    closeModal();
  };

  const onClickCloseButton = () => onCloseModal(onClickCloseButtonCallback);
  const onClickOutside = () => shouldCloseOnClickOutside && onCloseModal(onClickOutsideCallback);
  const onEscapeKeydown = () => shouldCloseOnEscapeKeydown && onCloseModal(onEscapeKeydownCallback);

  useOnClickOutside(modalRef, onClickOutside);
  useOnEscapeKeydown(modalRef, onEscapeKeydown);
  useTrapFocus(modalRef);
  useHandleHiddenAttribute();
  useHandleUrlUpdates(modal, shouldUpdateUrl, wasTriggeredFromUrl);

  useEffect(() => {
    modalRef.current.focus();
    document.documentElement.classList.add('mn_hasModal');
    return () => document.documentElement.classList.remove('mn_hasModal');
  }, [modalRef]);

  return (
    <div className={`mn_modalWrapper ${className}`} data-testid="overlay">
      <div
        className="mn_modal"
        ref={modalRef}
        tabIndex="-1"
        role="dialog"
        aria-modal="true"
        aria-labelledby="mn_dialogTitle"
        aria-describedby="mn_dialogDescription"
        {...attributes}
      >
        {shouldShowCloseButton &&
          <button className="mn_closeModal" onClick={onClickCloseButton} aria-label="Close dialog" />
        }
        <div className="mn_modalContent" data-testid={testId}>{children}</div>
      </div>
    </div>
  );
}

export default (props) => <ComponentErrorBoundary><Modal {...props} /></ComponentErrorBoundary>;
