import React from 'react';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';

import './UnlinkSuccessMessage.scss';

function UnlinkSuccessMessage({ hasMessage }) {
  if (!hasMessage) {
    return null;
  }

  return <div className="mn_instoreUnlinkSuccessMessage">Offer successfully unlinked from all cards.</div>;
}

export default (props) => <ComponentErrorBoundary><UnlinkSuccessMessage {...props} /></ComponentErrorBoundary>;
